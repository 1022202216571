export class ServiceBase {
    constructor(options) {}

    /* Uniform fetch for all services
  *
  * Params
  * - (string)    method              (required)
  * - (string)    url                 (required)
  * - (object)    data                See body https://developer.mozilla.org/en-US/docs/Web/API/fetch#parameters
  * - (object)    init                See init https://developer.mozilla.org/en-US/docs/Web/API/fetch#parameters
  *
  * Returns
  * - Promise
  *
  * */

    fetch(method,url,data,init){
        if(!method){throw 'method is not defined';}
        if(!url){throw 'url is not defined';}

        init = init ?? {};

        let initMerged = Object.assign({
            method: method,
            body: (method.toLowerCase() === 'post') ? data : null,
        }, init);

        return fetch(url, initMerged).then(response=>{
            // @NOTE
            // If we receive a 404, we will receive a success so we need to verify if the code status is an error and reject the promise.
            if (!response.ok || response.status < 200 || response.status > 300) {
                throw response;
            }

            return response;
        }).then(response=>{
            // @NOTE
            // Detect if the returned content is in json or html otherwise we return everything
            if(response.headers.get('content-type').includes('application/json')){
                return response.json()
            } else if(response.headers.get('content-type').includes('text/html')){
                return response.text();
            } else{
                return response;
            }
        }).then(response => {
            return response;
        }).catch(error => {
            throw error;
        });
    }
}
