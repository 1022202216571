import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore';

export class SideMenu extends ViewBasic {
    constructor(options) {
        super(options);

        this.lastClosest = 0;

        this.isFixed = false;
        this.menu = this.el.querySelector('.side-menu-wrapper');
        this.header = document.querySelector('.header');
        this.menuAccordion = document.querySelector('.menu-accordion');
        this.categories = document.querySelectorAll('.category-wrapper');

        this.setValues();
    }

    initialize() {
        bindAll(this, ['handleScroll', 'handleClick', 'handleChange', 'scrollTo', 'handleResize', 'setValues']);
        this.addEvents();
    }

    addEvents() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);
        this.el.querySelector('#select-categories').addEventListener('change', this.handleChange);
        this.el.querySelectorAll('.secondary-cta').forEach((el, index) => {
            el.addEventListener('click', this.handleClick);
        });
    }

    removeEvents() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
        this.el.querySelectorAll('.secondary-cta').forEach((el, index) => {
            el.removeEventListener('click', this.handleClick);
        });
    }

    setValues() {
        this.headerHeight = this.header.offsetHeight;
        this.menuHeight = this.menu.offsetHeight;
        this.containerTopOffset = window.scrollY + this.el.getBoundingClientRect().top;
        this.containerBottomOffset = this.containerTopOffset + this.el.offsetHeight;
        this.categoriesTop = [];
        this.categoriesIdTop = [];
        this.categories.forEach((el, index) => {
            this.categoriesTop.push(Math.trunc(window.scrollY + el.getBoundingClientRect().top));
            this.categoriesIdTop.push(
                {
                    categoryId: el.id,
                    categoryTop: Math.trunc(window.scrollY + el.getBoundingClientRect().top),
                }
            );
        });
    }

    handleResize() {
        this.setValues();
    }

    handleScroll(e) {
        // IF MOBILE OR IF NO STICKY, RETURN
        if(window.innerWidth < SETTINGS.BREAKPOINTS.SM_MIN || this.menu.classList.contains('no-sticky')){
            return;
        }

        const scrollPosition = window.lenis ? window.lenis.scroll : document.documentElement.scrollTop;

        // UPDATE POSITION ON SCROLL
        if (scrollPosition + this.headerHeight > this.containerTopOffset && scrollPosition + this.headerHeight < this.containerBottomOffset - this.menuHeight) {
            if (!this.isFixed) {
                this.menu.style.top = this.headerHeight + 'px';
                this.menu.classList.add('is-fixed');
                this.isFixed = true;
            }
        } else if (scrollPosition + this.headerHeight + this.menuHeight >= this.containerBottomOffset) {
            if (this.isFixed) {
                this.menu.style.top = this.el.offsetHeight - this.menuHeight + 'px';
                this.menu.classList.remove('is-fixed');
                this.isFixed = false;
            }
        } else {
            if (this.isFixed) {
                this.menu.style.top = '';
                this.menu.classList.remove('is-fixed');
                this.isFixed = false;
            }
        }

        // UPDATE ACTIVE ON SCROLL
        if (this.categoriesTop.length && this.el.querySelector('.side-menu-wrapper button')) {
            const menuMiddlePosition = Math.trunc(scrollPosition + this.headerHeight);
            const closest = this.categoriesTop.reduce(function(prev, curr) {
                return (Math.abs(curr - menuMiddlePosition) < Math.abs(prev - menuMiddlePosition) ? curr : prev);
            });

            if(closest !== this.lastClosest){
                let currentCategory = this.categoriesIdTop.filter(function (category) {
                    return category.categoryTop === closest;
                });
                this.menu.querySelectorAll('.secondary-cta').forEach((el, idx) => {
                    el.classList.remove('active');
                });
                this.el.querySelector('[data-category="'+ currentCategory[0].categoryId +'"]').classList.add('active');
            }

            this.lastClosest = closest;
        }
    }

    handleClick(e) {
        if(e){
            if(e.target.getAttribute("data-category")){
                this.scrollTo(this.el.querySelector('#' + e.target.getAttribute("data-category")));
            }
        }
    }

    handleChange(e) {
        if(e){
            if(e.target.value){
                this.scrollTo(this.el.querySelector('#' + e.target.value));
            }
        }
    }

    scrollTo(element, immediateBool = false) {
        let scope = this
        if (window.lenis) {
            window.lenis.scrollTo(element.offsetTop + scope.menuAccordion.offsetTop - document.querySelector('.primary-navigation').offsetHeight, {
                immediate: immediateBool
            });
        }
        else {
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: element.offsetTop + scope.menuAccordion.offsetTop - document.querySelector('.primary-navigation').offsetHeight
            });
        }

    }
}

Me.views['SideMenu'] = SideMenu;



