import {ViewBasic} from '@quatrecentquatre/manage-me';
import {bindAll} from 'underscore';

export class LoadMore extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['showContent']);
        this.addEvents();
    }

    addEvents() {
        this.el.addEventListener("click", this.showContent);
    }

    removeEvents() {
        this.el.removeEventListener("click", this.showContent);
    }

    showContent(){
        this.el.parentElement.querySelectorAll('.hide').forEach((element) => {
            element.classList.remove('hide');
        });

        this.el.classList.add('hide');
    }
}

Me.views['LoadMore'] = LoadMore;
