import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore';

export class ShowNextDate extends ViewBasic{
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['changeDate']);
        this.addEvents();
    }

    addEvents(){
        this.el.addEventListener('click', this.changeDate);
    }

    removeEvents(){
        this.el.removeEventListener('click', this.changeDate);
    }

    changeDate() {
        document.getElementById("secondaryDateFilter").value = this.el.getAttribute('data-date');
        document.getElementById("secondaryDateFilter").dispatchEvent(new Event('change'));
    }
}

Me.views['ShowNextDate'] = ShowNextDate;
