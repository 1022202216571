import {ViewBasic} from '@quatrecentquatre/manage-me';
import {bindAll} from 'underscore'

export class BigSelects extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['openBigSelect', 'closeBigSelect']);

        this.absoluteElement = this.el.querySelector('.absolute-wrapper');
        this.openFirst = false;

        this.addEvents();
    }

    addEvents() {
        this.el.querySelector('select').addEventListener('showDropdown', this.openBigSelect);
        this.el.querySelector('select').addEventListener('hideDropdown', this.closeBigSelect);
        // this.createObserver();
    }

    removeEvents() {
        this.el.querySelector('select').removeEventListener('showDropdown', this.openBigSelect);
        this.el.querySelector('select').removeEventListener('hideDropdown', this.closeBigSelect);
    }

    openBigSelect() {
        if (!window.isMobile) {
            let currentListDropdown = this.el.querySelector('.choices__list--dropdown');
            currentListDropdown.setAttribute('data-lenis-prevent', true);
            let currentTop = this.absoluteElement.offsetTop;
            let currentLeft = this.absoluteElement.offsetLeft;
            let currentWidth = this.absoluteElement.offsetWidth;
            let currentHeight = this.absoluteElement.offsetHeight;

            this.absoluteElement.parentElement.style.minWidth = currentWidth + 45 + 'px';
            this.absoluteElement.parentElement.style.minHeight = currentHeight + 'px';

            this.absoluteElement.style.top = currentTop + 'px';
            this.absoluteElement.style.left = currentLeft + 'px';
            this.absoluteElement.style.position = 'absolute';

            this.absoluteElement.animate([
                {
                    top: currentTop  + 'px',
                    left: currentLeft + 'px',
                },
                {
                    top: (currentTop - 25)  + 'px',
                    left: (currentLeft - 30) + 'px',
                }
            ], {
                duration: 200,
                fill: "both",
                easing: "cubic-bezier(0.455, 0.030, 0.515, 0.955)"
            });
        } else {
            let currentListDropdown = this.el.querySelector('.choices__list--dropdown');
            let currentSection = currentListDropdown.closest('section');

            window.lenis.stop();
            window.dispatchEvent(new CustomEvent("hideMenu"));

            currentListDropdown.style.opacity = 1;
            currentListDropdown.setAttribute('data-lenis-prevent', true);
            currentSection.style.position = 'relative';
            currentSection.style.zIndex = 99;
        }
    }

    closeBigSelect() {
        if(!window.isMobile) {
            let currentTop = this.absoluteElement.offsetTop;
            let currentLeft = this.absoluteElement.offsetLeft;

            this.absoluteElement.animate([
                {
                    top: currentTop  + 'px',
                    left: currentLeft + 'px',
                },
                {
                    top: '0px',
                    left: '0px',
                }
            ], {
                duration: 200,
                fill: "both",
                easing: "cubic-bezier(0.455, 0.030, 0.515, 0.955)"
            });

            let scope = this;
            setTimeout(function (){
                scope.absoluteElement.parentElement.style.minWidth = '';
                scope.absoluteElement.parentElement.style.minHeight = '';
                scope.absoluteElement.style.position = '';
                scope.absoluteElement.style.top = '';
                scope.absoluteElement.style.left = '';
            }, 200)
        } else {
            let currentListDropdown = this.el.querySelector('.choices__list--dropdown');
            let currentSection = currentListDropdown.closest('section');

            currentListDropdown.style.opacity = '';
            currentSection.style.position = '';
            currentSection.style.zIndex = '';

            window.lenis.start();
        }
    }

    // createObserver() {
    //     let scope = this;
    //     this.observer = new MutationObserver((mutationsList, observer) => {
    //         for (let mutation of mutationsList) {
    //             if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
    //                 if (mutation.target.classList.contains('is-open') && !scope.openFirst) {
    //                     scope.openFirst = true;
    //                     if (!window.isMobile) {
    //                         scope.openBigSelect();
    //                     } else {
    //                         scope.openBigSelectMobile();
    //                     }
    //                 } else  {
    //                     if(scope.openFirst) {
    //                         if (!window.isMobile) {
    //                             scope.closeBigSelect();
    //                         } else {
    //                             scope.closeBigSelectMobile();
    //                         }
    //                         scope.openFirst = false;
    //                     }
    //                 }
    //             }
    //         }
    //     });
    //
    //     this.observerOptions = {
    //         attributes: true,
    //         attributeFilter: ['class'],
    //     };
    //
    //     setTimeout(function (){
    //         scope.observer.observe(scope.el.querySelector('.choices'), scope.observerOptions);
    //     },0);
    // }
}

Me.views['BigSelects'] = BigSelects;
