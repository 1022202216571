import { Overlay } from './overlay';
import {bindAll} from "underscore";


export class ImageOverlay extends Overlay {
    constructor(options) {
        super(options);
    }

    initialize() {
        super.initialize();
        bindAll(this, ['createCookie','close','getCookie']);

        if(!this.getCookie('image_overlay')){
            window.dispatchEvent(new CustomEvent(Overlay.OVERLAY_OPEN,
                {
                    detail: {
                        id: 'overlay-image',
                    }
                }
            ));
        }
    }

    close(e){
        super.close(e);
        this.createCookie('image_overlay', 'true', 1);
    }

    createCookie(name, value, days) {
        const expires = days
          ? `; expires=${new Date(Date.now() + days * 86400000).toUTCString()}`
          : '';
        document.cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/`;
      }

    getCookie(name) {
        const cookies = document.cookie.split('; ');
        const foundCookie = cookies.find(cookie => cookie.startsWith(`${name}=`));
        return foundCookie ? decodeURIComponent(foundCookie.split('=')[1]) : '';
    }

}

Me.views['ImageOverlay'] = ImageOverlay;
