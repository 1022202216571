import {ViewBasic} from '@quatrecentquatre/manage-me';
import {bindAll} from 'underscore';

export class OptionPriceHandler extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        let textWithPrice = this.el.querySelector('[data-get-price]').innerHTML;
        let price = textWithPrice && textWithPrice.split('|')[1] ? textWithPrice.split('|')[1].replace('$', '').replace(/\s/g, '') : false;

        if (price) {
            if (this.el.querySelector('[data-print-price]')) {
                if (document.querySelector('html').getAttribute('lang') === 'fr') {
                    this.el.querySelector('[data-print-price]').innerHTML = price + ' $';
                } else {
                    this.el.querySelector('[data-print-price]').innerHTML = '$' + price;
                }
            }
            this.el.setAttribute('data-price', price);
        }
    }
}

Me.views['OptionPriceHandler'] = OptionPriceHandler;
