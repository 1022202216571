import { ServiceBase } from './service.base';

export class FiltersFormService extends ServiceBase {
    constructor(options) {
        super(options);
    }

    getFiltersResutls(url, formData){
        const headers = {
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
        };

        return this.fetch('GET', url, formData, {headers:headers}).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            // Error management for unique behavior wherever this service is called
            return Promise.reject(error);
        });
    }
}
