import { string, mixed, date } from 'yup';
import { FormBase } from '@quatrecentquatre/form-me';

export class JobsForm extends FormBase {
    constructor(options) {
        super(options);
        //set default variables

        this.phoneRegExp = /^(?:\+?1)?[-. ]?\(?[2-9][0-8][0-9]\)?[-. ]?[2-9][0-9]{2}[-. ]?[0-9]{4}$/i;

        this.jobsList = this.$el.querySelector('[name="fields[jobs_list][]"]');
        this.jobsParks = this.$el.querySelector('[name="fields[jobs_parks][]"]');
        this.jobsDisponibility = this.$el.querySelector('[name="fields[jobs_disponibility]"]');
        this.jobsStartDate = this.$el.querySelector('[name="fields[jobs_start_date][datetime]"]');
        this.jobsName = this.$el.querySelector('[name="fields[jobs_name]"]');
        this.jobsCv = this.$el.querySelector('[name="fields[jobs_cv]"]');
        this.jobsMessage = this.$el.querySelector('[name="fields[jobs_message]"]');
        this.jobsEmail = this.$el.querySelector('[name="fields[jobs_email]"]');

        this.addFields([
            {
                name: 'fields[jobs_list][]',
                validationSchema: mixed().required(),
                $error: this.jobsList.parentElement.parentElement.parentElement.querySelector('.error-msg'),
                $targetErrorClass: this.jobsList.parentElement.parentElement.parentElement,
            },
            {
                name: 'fields[jobs_parks][]',
                validationSchema: mixed().required(),
                $error: this.jobsParks.parentElement.parentElement.parentElement.querySelector('.error-msg'),
                $targetErrorClass: this.jobsParks.parentElement.parentElement.parentElement,
            },
            {
                name: 'fields[jobs_disponibility]',
                validationSchema: string().required(),
                $error: this.jobsDisponibility.parentElement.parentElement.parentElement.querySelector('.error-msg'),
                $targetErrorClass: this.jobsDisponibility.parentElement.parentElement.parentElement,
            },
            {
                name: 'fields[jobs_start_date][datetime]',
                validationSchema: date().min(new Date()).required(),
                $error: this.jobsStartDate.parentElement.querySelector('.error-msg'),
            },
            {
                name: 'fields[jobs_name]',
                validationSchema: string().required(),
                $error: this.jobsName.parentElement.querySelector('.error-msg'),
            },
            {
                name: 'fields[jobs_email]',
                validationSchema: string().email().required(),
                $error: this.jobsEmail.parentElement.querySelector('.error-msg'),
            },
            {
                name: 'fields[jobs_cv]',
                validationSchema: mixed()
                    .test('is-valid-file',  function (value) {
                        // Check if value is empty or null
                        if (!value || !value.name) return true; // Skip this validation if empty
                        const allowedExtensions = /\.(doc|pdf|docx?)$/i;
                        return allowedExtensions.test(value.name);
                    })
                    .test('is-valid-size', function (value) {
                        if (!value || !value.name) return true;
                        return value.size <= 2000000;
                    }).notRequired(),
                $error: this.jobsCv.parentElement.parentElement.parentElement.querySelector('.error-msg'),
                $targetErrorClass: this.jobsCv.parentElement.parentElement.parentElement,
            },
            {
                name: 'fields[jobs_message]',
                validationSchema: string().required(),
                $error: this.jobsMessage.parentElement.querySelector('.error-msg'),
            },
        ])
    }

    initialize() {
        super.initialize();
        this.button = this.$el.querySelector('button[type="submit"]');
    }

    /**
     * Duplicate to fix JS error in form-me.js
     */
    handleValidationSuccess() {
        this.$el.classList.add(this.classes.valid), this.recaptcha && typeof grecaptcha < "u" ? grecaptcha.execute(SETTINGS.RECAPTCHA_SITE_KEY, { action: this.recaptchaAction }).then((e) => {
            this.$recaptchaInput.value = e, this.handleAjaxSend(this.formatData(new FormData(this.$el)));
        }) : this.handleAjaxSend(this.formatData(new FormData(this.$el)));
    }

    /**
     * Scroll to error position. Query selector finds first error.
     */

    handleValidationError() {
        super.handleValidationError()

        // Scroll to position of error.
        const targetElement = this.$el.querySelector('.has-error');
        const { top } = targetElement.getBoundingClientRect();
        const targetOffset = window.scrollY + top - 200;

        window.scroll({
            top: targetOffset,
            behavior: 'smooth'
        });

    }
    handleAjaxSend(e) {
        this.button.disabled = true
        this.addLoader();

        const t = this.method === "GET" ? `${this.action}?${new URLSearchParams(e)}` : this.action, s = {
            method: this.method,
            cache: "no-cache"
        };
        this.method === "POST" && (s.body = e), fetch(t, s).then((r) => {
            if (!r.ok)
                throw new Error(r.statusText);
            return r.json();
        }).then((r) => {
            r && this.ajaxSuccess(r);
        }).catch((r) => r.response ? r.response.json() : { error: "Unknown error" }).then((r) => {
            r && this.ajaxError(r);
        }).finally(() => {
            this.ajaxComplete();
        });
    }

    ajaxError(error) {
        this.$el.querySelector('.success-message').classList.add('hide');
        this.$el.classList.add(this.classes.serverError);
        this.$el.querySelector('.error-message').innerText = this.$el.querySelector('.error-message').getAttribute('data-default');
        this.$el.querySelector('.error-message').classList?.remove('hide');
    }

    ajaxSuccess(response) {
        if (response.success) {
            let selectedJobs = '';
            if (this.$el.querySelector('[name="fields[jobs_list][]"]').tagName === 'SELECT') {
                selectedJobs = Array.from(this.jobsList.options).filter(function (option) {
                    return option.selected;
                }).map(function (option) {
                    return option.text;
                });
                if (selectedJobs.length > 1){
                    selectedJobs = selectedJobs.join('-');
                } else {
                    selectedJobs = selectedJobs.toString();
                }
            } else {
                selectedJobs = this.$el.querySelector('[name="job_name"]').value;
            }
            let selectedParks = Array.from(this.jobsParks.options).filter(function (option) {
                return option.selected;
            }).map(function (option) {
                return option.text;
            });
            if (selectedParks.length > 1){
                selectedParks = selectedParks.join('-');
            } else {
                selectedParks = selectedParks.toString();
            }

            let eventID = this.$el.querySelector('[name="fields[jobs_list][]"]').tagName === 'SELECT' ? 'spontaneous_job_form' : 'job_form';

            window.dispatchEvent(new CustomEvent('dataLayer.push', {
                detail: {
                    'event': 'ccformSubmission',
                    'ccformID': eventID,
                    'poste': selectedJobs,
                    'parc': selectedParks
                }
            }));

            // Reset form
            this.$el.reset();

            // Remove has-value classes from all form fields
            let hasValue = this.$el.querySelectorAll('.has-value')
            if(hasValue){
                hasValue.forEach((entry) => {
                    entry.classList.remove('has-value');
                })
            }

            // Dispatch event to the select js
            // Once all fields are reset, we need to destroy the Choices object in Selects.js and re-init
            document.dispatchEvent(new CustomEvent('resetSelect'));

            // Show success message, hide error if visible
            this.$el.querySelector('.success-message').classList?.remove('hide');
            this.$el.querySelector('.error-message').classList?.add('hide');
        } else {
            // Show error message, hide success if visible
            this.$el.querySelector('.success-message').classList?.add('hide');
            this.$el.querySelector('.error-message').innerText = response.errors[0];
            this.$el.querySelector('.error-message').classList?.remove('hide');
        }
    }

    ajaxComplete() {
        super.ajaxComplete();
        this.removeLoader();

        // Enable submit button
        this.button.disabled = false;

        setTimeout(() =>{
            this.$el.querySelector('.success-message').classList?.add('hide');
        },5000)
    }

    resetFieldState(field){
        // Remove has-error from field
        super.resetFieldState(field);
        if(field.$targetErrorClass){
            field.$targetErrorClass.classList.remove(this.classes.error)
        }
    }

    setFieldState(field, error) {
        // Remove has-error to parent field (used for $targetErrorClass at line 22)
        super.setFieldState(field, error);
        if(field.$targetErrorClass){
            field.$targetErrorClass.classList.add(this.classes.error)
        }
    }

    addLoader(){
        const spinnerWrapper = document.createElement('div');
        spinnerWrapper.className = 'spinner-wrapper';
        const loader = document.createElement('span');
        loader.className = 'loader';

        spinnerWrapper.appendChild(loader);

        this.$el.appendChild(spinnerWrapper);
    };

    removeLoader() {
        this.$el.parentNode.querySelector('.spinner-wrapper')?.remove();
    }



}

Me.forms['JobsForm'] = JobsForm;
