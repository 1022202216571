import { Overlay } from './overlay';

export class SwitchSiteOverlay extends Overlay {
    constructor(options) {
        super(options);
    }

    open(e){
        if(e.detail.id != this.el.id){
            return;
        }

        super.open(e);

        // Add next page URL to button
        this.el.querySelector('a').href = e.detail.type;
        this.el.querySelector('.wrapper').scrollTop = 0;
    }
}

Me.views['SwitchSiteOverlay'] = SwitchSiteOverlay;
