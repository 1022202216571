import { string } from 'yup';
import { FormBase } from '@quatrecentquatre/form-me';
import Choices from 'choices.js';
import 'choices.js/src/styles/choices.scss'
import {bindAll} from "underscore";

export class GroupsForm extends FormBase {
    constructor(options) {
        super(options);
        //set default variables

        this.firstTime = true;
        this.phoneRegExp = /^(?:\+?1)?[-. ]?\(?[2-9][0-8][0-9]\)?[-. ]?[2-9][0-9]{2}[-. ]?[0-9]{4}$/i;

        this.groupActivities = this.$el.querySelector('[name="fields[form_group_activity][]"]');
        this.parks = this.$el.querySelector('[name="fields[form_parks][]"]');
        this.userName = this.$el.querySelector('[name="fields[form_name]"]');
        this.email = this.$el.querySelector('[name="fields[form_email]"]');
        this.organization = this.$el.querySelector('[name="fields[form_organization]"]');
        this.participants = this.$el.querySelector('[name="fields[form_participants]"]');
        this.message = this.$el.querySelector('[name="fields[form_message]"]');
        this.fieldPhone = this.$el.querySelector('[name="fields[form_phone_number]"]');

        this.addFields([
            {
                name: 'fields[form_group_activity][]',
                validationSchema: string().required(),
                $error: this.groupActivities.parentElement.parentElement.parentElement.querySelector('.error-msg'),
                $targetErrorClass: this.groupActivities.parentElement.parentElement.parentElement,
            },
            {
                name: 'fields[form_parks][]',
                validationSchema: string().required(),
                $error: this.parks.parentElement.parentElement.parentElement.querySelector('.error-msg'),
                $targetErrorClass: this.parks.parentElement.parentElement.parentElement,
            },
            {
                name: 'fields[form_name]',
                validationSchema: string().required(),
                $error: this.userName.parentElement.querySelector('.error-msg'),
            },
            {
                name: 'fields[form_email]',
                validationSchema: string().email().required(),
                $error: this.email.parentElement.querySelector('.error-msg'),
            },
            {
                name: 'fields[form_phone_number]',
                validationSchema: string().matches(this.phoneRegExp).required(),
                $error: this.fieldPhone.parentElement.querySelector('.error-msg'),
            },
            {
                name: 'fields[form_organization]',
                validationSchema: string().required(),
                $error: this.organization.parentElement.querySelector('.error-msg'),
            },
            {
                name: 'fields[form_participants]',
                validationSchema: string().required(),
                $error: this.participants.parentElement.querySelector('.error-msg'),
            },
            {
                name: 'fields[form_message]',
                validationSchema: string().required(),
                $error: this.message.parentElement.querySelector('.error-msg'),
            },
        ])
    }
    initialize() {
        bindAll(this, ['sortParks']);
        this.choices = {}
        this.button = this.$el.querySelector('button[type="submit"]');
        this.introButton = document.querySelector('.anchor-form');
        this.addEvents();
        super.initialize();
    }

    /**
     * Duplicate to fix JS error in form-me.js
     */
    handleValidationSuccess() {
        this.$el.classList.add(this.classes.valid), this.recaptcha && typeof grecaptcha < "u" ? grecaptcha.execute(SETTINGS.RECAPTCHA_SITE_KEY, { action: this.recaptchaAction }).then((e) => {
            this.$recaptchaInput.value = e, this.handleAjaxSend(this.formatData(new FormData(this.$el)));
        }) : this.handleAjaxSend(this.formatData(new FormData(this.$el)));
    }

    addEvents() {
        super.addEvents();
        this.groupActivities.addEventListener("change", this.sortParks);
        this.introButton.addEventListener("click", () => {
            if (window.lenis) {
                window.lenis.scrollTo(this.$el.parentElement.parentElement);
            }
        });

        document.addEventListener('sortParks', this.sortParks);
    }

    removeEvents() {
        super.removeEvents()
        this.groupActivities.removeEventListener("change", this.sortParks)
        this.introButton.removeEventListener("click", () => {
            if (window.lenis) {
                window.lenis.scrollTo(this.$el.parentElement.parentElement);
            }
        });
        document.removeEventListener('sortParks', this.sortParks);
    }

    /**
     * This code updates the available park choices based on the selected activity, dynamically adjusting the options displayed to the user.
     */
    sortParks() {
        const selectedActivity = this.groupActivities.value;
        // Clear existing options
        if(this.firstTime){
            // Need to destroy the select in selects.js
            document.dispatchEvent(new CustomEvent('destroySpecificChoice', {
                detail: {
                    element: this.$el.querySelector('[name="fields[form_parks][]"]')
                }
            }));
            this.firstTime = false;
        } else {
            this.choices.destroy();
        }
        this.choices = new Choices(this.parks, {
            shouldSort: false,
            removeItemButton: true,
            searchEnabled: false,
            searchChoices: false,
            position: 'bottom',
            allowHTML: false,
        });

        // Retrieve the list of location options
        const locationList = this.parks.parentElement.parentElement.querySelector(".choices__list--dropdown");
        const locationOptions = locationList.querySelectorAll(".choices__item--choice");

        // Filter the location options based on the selected activity
        const filteredOptions = Array.from(locationOptions).filter((option) => {
            const optionValue = option.getAttribute("data-value");

            // We extract digits of the activity because the use of a data-attribute does not seem possible with Choices.js.
            // Currently, the first 4 digits represent the Park ID and last 4 are the activity ID.
            const activityId = optionValue.split('|')[1]; // Extract last 4 digits

            return activityId === selectedActivity;
        });

        // Create an array of parks from the activity selected
        const choices = filteredOptions.map((option) => {
            const dataValue = option.getAttribute("data-value");

            // We keep only the 4 digits for the parkId.
            const parkId = dataValue.split('|')[0];
            if (parkId){
                // Show all parks that are related to the activity.
                return {
                    value: parkId,
                    label: option.textContent
                };
            }
        });

        // Update the choices instance with the new options
        this.choices.setChoices(choices, "value", "label", true);
        this.choices.enable();
    }


    /**
     * Scroll to error position. Query selector finds first error.
     */

    handleValidationError() {
        super.handleValidationError()

        // Scroll to position of error.
        const targetElement = this.$el.querySelector('.has-error');
        const { top } = targetElement.getBoundingClientRect();
        const targetOffset = window.scrollY + top - 200;

        window.scroll({
            top: targetOffset,
            behavior: 'smooth'
        });

    }
    handleAjaxSend(e) {
        this.button.disabled = true
        this.addLoader();

        const t = this.method === "GET" ? `${this.action}?${new URLSearchParams(e)}` : this.action, s = {
            method: this.method,
            cache: "no-cache"
        };
        this.method === "POST" && (s.body = e), fetch(t, s).then((r) => {
            if (!r.ok)
                throw new Error(r.statusText);
            return r.json();
        }).then((r) => {
            r && this.ajaxSuccess(r);
        }).catch((r) => r.response ? r.response.json() : { error: "Unknown error" }).then((r) => {
            r && this.ajaxError(r);
        }).finally(() => {
            this.ajaxComplete();
        });
    }

    ajaxError(error) {
        this.$el.querySelector('.success-message').classList.add('hide');
        this.$el.classList.add(this.classes.serverError);
        this.$el.querySelector('.error-message').innerText = this.$el.querySelector('.error-message').getAttribute('data-default');
        this.$el.querySelector('.error-message').classList?.remove('hide');
    }

    ajaxSuccess(response) {
        if (response.success) {
            window.dispatchEvent(new CustomEvent('dataLayer.push', {
                detail: {
                    'event': 'ccformSubmission',
                    'ccformID': 'group_quote_form',
                    'activite': this.groupActivities.options[this.groupActivities.selectedIndex].text,
                    'parc': this.parks.options[this.parks.selectedIndex].text,
                    'type': this.$el.querySelector('[name="group_name"]').value
                }
            }));

            // Reset form
            this.$el.reset();

            // Remove has-value classes from all form fields
            let hasValue = this.$el.querySelectorAll('.has-value')
            if(hasValue){
                hasValue.forEach((entry) => {
                    entry.classList.remove('has-value');
                })
            }

            // Dispatch event to the select js
            // Once all fields are reset, we need to destroy the Choices object in Selects.js and re-init
            document.dispatchEvent(new CustomEvent('resetSelect'));

            this.choices.destroy();
            this.choices.init();
            this.choices.disable();

            // Show success message, hide error if visible
            this.$el.querySelector('.success-message').classList?.remove('hide');
            this.$el.querySelector('.error-message').classList?.add('hide');
        } else {
            // Show error message, hide success if visible
            this.$el.querySelector('.success-message').classList?.add('hide');
            this.$el.querySelector('.error-message').innerText = response.errors[0];
            this.$el.querySelector('.error-message').classList?.remove('hide');
        }
    }

    ajaxComplete() {
        super.ajaxComplete();
        this.removeLoader();

        // Enable submit button
        this.button.disabled = false;

        setTimeout(() =>{
            this.$el.querySelector('.success-message').classList?.add('hide');
        },5000)
    }

    resetFieldState(field){
        // Remove has-error from field
        super.resetFieldState(field);
        if(field.$targetErrorClass){
            field.$targetErrorClass.classList.remove(this.classes.error)
        }
    }

    setFieldState(field, error) {
        // Remove has-error to parent field (used for $targetErrorClass at line 22)
        super.setFieldState(field, error);
        if(field.$targetErrorClass){
            field.$targetErrorClass.classList.add(this.classes.error)
        }
    }

    addLoader(){
        const spinnerWrapper = document.createElement('div');
        spinnerWrapper.className = 'spinner-wrapper';
        const loader = document.createElement('span');
        loader.className = 'loader';

        // spinnerBorder.appendChild(loader);
        spinnerWrapper.appendChild(loader);

        this.$el.appendChild(spinnerWrapper);
    };

    removeLoader() {
        this.$el.parentNode.querySelector('.spinner-wrapper')?.remove();
    }



}

Me.forms['GroupsForm'] = GroupsForm;
