import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore';

export class CartCodeHandler extends ViewBasic{
    constructor(options) {
        super(options);

        this.submit = this.el.querySelector('.submit');
        this.input = this.el.querySelector('input[type="text"]');
        this.tagsList = this.el.querySelector('.tags-list');

        this.tagButtons = [];

        this.tagTemplate = '<div class="tag">\n' +
                           '    <p class="tag-text x-small">%text%</p>\n' +
                           '    <button class="close">\n' +
                           '        <span class="line"></span>\n' +
                           '        <span class="line"></span>\n' +
                           '    </button>\n' +
                           '</div>'


    }

    initialize() {
        bindAll(this, ['handleSubmit', 'removeTag', 'saveCodeToInput', 'autofillContent']);
        this.addEvents();
        this.autofillContent();
    }

    addEvents(){
        this.submit.addEventListener('click', this.handleSubmit);

        let scope = this;
        this.tagButtons.forEach((button) => {
            button.addEventListener('click', scope.removeTag);
        });
    }

    removeEvents(){
        this.submit.removeEventListener('click', this.handleSubmit);

        let scope = this;
        this.tagButtons.forEach((button) => {
            button.removeEventListener('click', scope.removeTag);
        });
    }

    autofillContent(){
        if(this.el.querySelector('input[type="hidden"]').value !== ''){
            this.input.value = this.el.querySelector('input[type="hidden"]').value;
            this.handleSubmit();
        }
    }

    handleSubmit(e) {
        if (e) {
            e.preventDefault();
        }

        let scope = this;
        let inputValue = this.input.value;
        if (!inputValue.length > 0) {
            return;
        }

        if (inputValue.indexOf(',') > -1) {
            let multipleValues = inputValue.split(',');
            multipleValues.forEach((value) => {
                scope.tagsList.innerHTML = scope.tagsList.innerHTML + scope.tagTemplate.replace('%text%', value);
            });
        } else {
            this.tagsList.innerHTML = this.tagsList.innerHTML + this.tagTemplate.replace('%text%', inputValue);
        }

        this.input.value = '';
        this.input.classList.remove('has-value');
        this.tagButtons = this.el.querySelectorAll('.close') ? this.el.querySelectorAll('.close') : [];

        window.dispatchEvent(new Event('resize'));

        this.saveCodeToInput();

        this.removeEvents();
        this.addEvents();
    }

    removeTag(e) {
        e.preventDefault();
        e.currentTarget.closest('.tag').remove();

        this.saveCodeToInput();
    }

    saveCodeToInput() {
        let codes = '';
        this.tagsList.querySelectorAll('.tag-text').forEach((tag, index) => {
            if (index < 1) {
                codes += tag.innerHTML;
            } else {
                codes += ',' + tag.innerHTML;
            }
        });

        this.el.querySelector('[type="hidden"]').value = codes;
    }
}

Me.views['CartCodeHandler'] = CartCodeHandler;
