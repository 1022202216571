import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore'

export class Breadcrumb extends ViewBasic{
    constructor(options) {
        super(options);

        this.breadcrumb = this.el.querySelector('.breadcrumb-nav-wrapper');
    }

    initialize() {
        bindAll(this, ['checkBreadcrumb']);
        this.addEvents();
        this.checkBreadcrumb();
    }

    addEvents() {
        window.addEventListener('resize', this.checkBreadcrumb);
    }

    removeEvents() {
        window.removeEventListener('resize', this.checkBreadcrumb);
    }

    /**
     * Function to add three dots if the breadcrumb navigation is too wide in mobile.
     */
    checkBreadcrumb(e){
        if (this.breadcrumb && this.breadcrumb.classList.contains('has-parent-page')){
            if(window.innerWidth < SETTINGS.BREAKPOINTS.SM_MIN || window.isMobile){
                if(!this.breadcrumb.classList.contains('trunked') && this.breadcrumb.scrollWidth >= (window.innerWidth - 40)){
                    this.breadcrumb.classList.add('trunked');
                    this.breadcrumb.setAttribute("width", this.breadcrumb.scrollWidth);
                    // Target the two first breadcrumb elements
                    const liElements = document.querySelectorAll('.c-breadcrumb__item:nth-child(-n+2)');
                    liElements.forEach((li, index) => {
                        // Find the span element with the class "label" within each li element
                        const labelSpan = li.querySelector('span.label');
                        if(index === 0) {
                            if (labelSpan) {
                                li.setAttribute("text", labelSpan.textContent);
                                labelSpan.textContent = "...";
                            }
                        }
                    });
                } else if(this.breadcrumb.classList.contains('trunked') && this.breadcrumb.getAttribute("width") && this.breadcrumb.getAttribute("width") < (window.innerWidth - 40)) {
                    this.breadcrumb.classList.remove('trunked')
                    // Target the two first breadcrumb elements
                    const liElements = document.querySelectorAll('.c-breadcrumb__item:nth-child(-n+2)');
                    liElements.forEach((li, index) => {
                        // Find the span element with the class "label" within each li element
                        const labelSpan = li.querySelector('span.label');
                        if(index === 0) {
                            if (labelSpan) {
                                labelSpan.textContent = li.getAttribute("text");
                            }
                        }
                    });
                }
            } else {
                if(this.breadcrumb.classList.contains('trunked') && this.breadcrumb.getAttribute("width") && this.breadcrumb.getAttribute("width") < window.innerWidth) {
                    this.breadcrumb.classList.remove('trunked')
                    // Target the two first breadcrumb elements
                    const liElements = document.querySelectorAll('.c-breadcrumb__item:nth-child(-n+2)');
                    liElements.forEach((li, index) => {
                        // Find the span element with the class "label" within each li element
                        const labelSpan = li.querySelector('span.label');
                        if(index === 0) {
                            if(labelSpan) {
                                labelSpan.textContent = li.getAttribute("text");
                            }
                        }
                    });
                }
            }
        }
    }


}

Me.views['Breadcrumb'] = Breadcrumb;
