import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore';
export class JobsAnchor extends ViewBasic {
    constructor(options) {
        super(options);
        // Set default variables
    }

    initialize() {
        bindAll(this, ['handleScroll']);
        this.addEvents();
    }

    addEvents() {
        window.addEventListener('scroll', this.handleScroll);
    }

    removeEvents() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(e){
        let btnDimensions = this.el.getBoundingClientRect();
        let listDimensions = document.querySelector('#jobs-list').getBoundingClientRect();

        if (btnDimensions.bottom >= listDimensions.top) {
            if (!this.el.classList.contains('hide-btn')){
                this.el.classList.add('hide-btn');
            }
        } else {
            if (this.el.classList.contains('hide-btn')){
                this.el.classList.remove('hide-btn');
            }
        }
    }
}

Me.views['JobsAnchor'] = JobsAnchor;
