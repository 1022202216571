import {Button} from "../button";
import { bindAll } from "underscore";
import {Overlay} from "./overlay";

export class SiteOverlayBtn extends Button {
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['changeWebsite']);
        super.initialize();
    }

    addEvents() {
        super.addEvents();
        this.el.addEventListener('click', this.changeWebsite);
    }

    removeEvents() {
        super.removeEvents();
        this.el.removeEventListener('click', this.changeWebsite);
    }

    changeWebsite(e) {
        e.preventDefault();
        e.stopPropagation();

        let currentURL = window.location.href;
        const dataUrl = this.el.getAttribute('data-url');

        if(!currentURL.includes(dataUrl)){
            window.dispatchEvent(new CustomEvent(Overlay.OVERLAY_OPEN,
                {
                    detail: {
                        id: 'overlay-change-website',
                        type: dataUrl
                    }
                }
            ));
        }

    }
}

Me.views['SiteOverlayBtn'] = SiteOverlayBtn;
