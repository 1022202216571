import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { bindAll } from 'underscore'

export class Hero extends ViewBasic{
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['clearTimeline', 'generateTimeline']);

        this.gutter = 20;
        if (this.el.querySelector('.background-wrapper img')) {
            this.generateTimeline();
        }
        this.addEvents();
    }

    addEvents() {}

    removeEvents() {}

    /**
     * Reset timeline to prevent visual glitches and stacking of events
     */
    clearTimeline(){
        if(this.timeline){
            //reset timeline
            this.timeline.pause(0).kill(true);
        }
    }

    /**
     * Generate a timeline with scrolltrigger.
     * The animation is different for mobile and other resolutions
     */
    generateTimeline(){
        let scope = this;
        let $element = this.el;

        this.clearTimeline();

        this.timeline = gsap.timeline({
            scrollTrigger: {
                id: 'hero',
                trigger: $element,
                start: "top",
                end: "+=600",
                scrub: false,
                //markers: true,
                onUpdate: self => {
                    //save progress for further use outside this update function
                    scope.timelineProgress = self.progress;

                    //seek to a percentage of the timeline based on progress to play the animation
                    scope.timeline.seek(scope.timeline.duration() * self.progress, false);
                    //prevent timeline to autoplay.
                    scope.timeline.pause();

                    //debug
                    //console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());
                }
            }
        });

        this.timeline.to($element.querySelector('.background-wrapper img'), {scale: '1.2', duration: 3, ease: 'none'},0);

        //pause the timeline since it will be seeked with the scrolltrigger
        this.timeline.pause();

    }
}

Me.views['Hero'] = Hero;
