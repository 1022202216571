import { string } from 'yup';
import { FormBase } from '@quatrecentquatre/form-me';

export class NewsletterForm extends FormBase {
    constructor(options) {
        super(options);
        //set default variables

        this.fieldEmail = this.$el.querySelector('[name="fields[form_email]"]');

        this.addFields([
            {
                name: 'fields[form_email]',
                validationSchema: string().email().required(),
                $error: this.fieldEmail.parentElement.querySelector('.error-msg'),
            }
        ])
    }

    initialize() {
        super.initialize();
        this.button = this.$el.querySelector('button[type="submit"]');
    }

    /**
     * Duplicate to fix JS error in form-me.js
     */
    handleValidationSuccess() {
        this.$el.classList.add(this.classes.valid), this.recaptcha && typeof grecaptcha < "u" ? grecaptcha.execute(SETTINGS.RECAPTCHA_SITE_KEY, { action: this.recaptchaAction }).then((e) => {
            this.$recaptchaInput.value = e, this.handleAjaxSend(this.formatData(new FormData(this.$el)));
        }) : this.handleAjaxSend(this.formatData(new FormData(this.$el)));
    }

    /**
     * Scroll to error position. Query selector finds first error.
     */
    handleValidationError() {
        super.handleValidationError()

        // Scroll to position of error.
        const targetElement = this.$el.querySelector('.has-error');
        const { top } = targetElement.getBoundingClientRect();
        const targetOffset = window.scrollY + top - 200;

        window.scroll({
            top: targetOffset,
            behavior: 'smooth'
        });

    }

    handleAjaxSend(e) {
        this.button.disabled = true

        const t = this.method === "GET" ? `${this.action}?${new URLSearchParams(e)}` : this.action, s = {
            method: this.method,
            cache: "no-cache"
        };
        this.method === "POST" && (s.body = e), fetch(t, s).then((r) => {
            if (!r.ok)
                throw new Error(r.statusText);
            return r.json();
        }).then((r) => {
            r && this.ajaxSuccess(r);
        }).catch((r) => r.response ? r.response.json() : { error: "Unknown error" }).then((r) => {
            r && this.ajaxError(r);
        }).finally(() => {
            this.ajaxComplete();
        });
    }

    ajaxError(error) {
        this.$el.querySelector('.success-message').classList.add('hide');
        this.$el.classList.add(this.classes.serverError);
        this.$el.querySelector('.error-message').innerText = this.$el.querySelector('.error-message').getAttribute('data-default');
        this.$el.querySelector('.error-message').classList?.remove('hide');
    }

    ajaxSuccess(response) {
        if (response.success) {
            // Reset form
            this.$el.reset();

            // Remove has-value classes from all form fields
            let hasValue = this.$el.querySelectorAll('.has-value')
            if(hasValue){
                hasValue.forEach((entry) => {
                    entry.classList.remove('has-value');
                })
            }

            // Dispatch event to the select js
            // Once all fields are reset, we need to destroy the Choices object in Selects.js and re-init
            document.dispatchEvent(new CustomEvent('resetSelect'));

            // Show success message, hide error if visible
            this.$el.querySelector('.success-message').classList?.remove('hide');
            this.$el.querySelector('.error-message').classList?.add('hide');

            window.dispatchEvent(new CustomEvent('dataLayer.push', {
                detail: {
                    'event': 'ccformSubmission',
                    'ccformID': 'newsletter_form'
                }
            }));
        } else {
            // Show error message, hide success if visible
            this.$el.querySelector('.success-message').classList?.add('hide');
            this.$el.querySelector('.error-message').innerText = response.errors[0];
            this.$el.querySelector('.error-message').classList?.remove('hide');
        }
    }

    ajaxComplete() {
        super.ajaxComplete();

        // Enable submit button
        this.button.disabled = false;

        setTimeout(() =>{
            this.$el.querySelector('.success-message').classList?.add('hide');
        },5000)
    }

    resetFieldState(field){
        // Remove has-error from field
        super.resetFieldState(field);
        if(field.$targetErrorClass){
            field.$targetErrorClass.classList.remove(this.classes.error)
        }
    }
    setFieldState(field, error){
        // Remove has-error to parent field (used for $targetErrorClass at line 22)
        super.setFieldState(field, error);
        if(field.$targetErrorClass){
            field.$targetErrorClass.classList.add(this.classes.error)
        }
    }

    addLoader(){
        const lang = document.querySelector('html').getAttribute('lang');
        const loaderText = lang === 'fr' ? 'Chargement...' : 'Loading...';

        const spinnerWrapper = document.createElement('div');
        spinnerWrapper.className = 'spinner-wrapper';
        const loader = document.createElement('span');
        loader.className = 'loader';

        // spinnerBorder.appendChild(loader);
        spinnerWrapper.appendChild(loader);

        this.$el.appendChild(spinnerWrapper);
    };

    removeLoader() {
        this.$el.parentNode.querySelector('.spinner-wrapper')?.remove();
    }
}

Me.forms['NewsletterForm'] = NewsletterForm;
