import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import {bindAll} from "underscore";

export class ScrollingHomepageParks extends ViewBasic{
    constructor(options) {
        super(options);
        //set default variables
    }

    initialize() {
        bindAll(this, ['createTimeline']);
        if (!window.isMobile) {
            this.createTimeline();
        }
    }

    createTimeline() {
        let $element = this.el;
        let buttons = $element.querySelectorAll('.park .primary-cta');

        ScrollTrigger.create({
            trigger: '.parks-section > .park:nth-child(2)',
            start: "top 50%",
            end: "bottom 0%",
            onEnter: () => {
                gsap.to($element, { duration: 0.5, backgroundColor: '#E8E6DD'});
            },
            onLeaveBack: () => {
                gsap.to($element, { duration: 0.5, backgroundColor: '#98D6E7'});
            },
        });
        ScrollTrigger.create({
            trigger: '.parks-section > .park:nth-child(3)',
            start: "top 50%",
            end: "bottom 0%",
            onEnter: () => {
                $element.classList.add('dark-green');
                buttons.forEach((button) => {
                    button.classList.add('alternate');
                });
                gsap.to($element, { duration: 0.5, backgroundColor: '#0f5753'});
            },
            onLeaveBack: () => {
                $element.classList.remove('dark-green');
                buttons.forEach((button) => {
                    button.classList.remove('alternate');
                });
                gsap.to($element, { duration: 0.5, backgroundColor: '#E8E6DD'});
            },
        });

        this.timeline = gsap.timeline({
            scrollTrigger: {
                id: 'homepage-parks',
                trigger: $element.querySelector('.scrolling-section'),
                start: "top",
                end: "bottom bottom",
                scrub: false,
                //markers: true,
                onUpdate: self => {
                    let progress = self.progress.toFixed(3);

                    $element.querySelectorAll('.images-section picture').forEach((image, index) => {
                        //only animate first image if progress is bigger than 0 (always)
                        //only animate 2nd image if progress is bigger than .50 (halfway during the section scrolltrigger)
                        //never animate 3rd image, it will always stick underneath everything and move with the content at the end(position sticky)
                        if(index * 0.5 < progress){
                            if(index == 1){
                                //2nd image works differently, we wanna translate it following the scroll, but we need to add half the container height (minus the screenheight). So once we are at halfway, it's like we starting from 0 again.
                                image.style.transform = `translateY(${$element.querySelector('.scrolling-section').getBoundingClientRect().top + ($element.querySelector('.scrolling-section').offsetHeight/2) - window.innerHeight/2}px)`;
                            }else{
                                image.style.transform = `translateY(${$element.querySelector('.scrolling-section').getBoundingClientRect().top}px)`;
                            }
                        }else{
                            image.style.transform = `translateY(0px)`;
                        }
                    });
                    //debug
                    // console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());
                }
            }
        });
    }
}

Me.views['ScrollingHomepageParks'] = ScrollingHomepageParks;
