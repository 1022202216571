import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore';

export class MobileNavigation extends ViewBasic{
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['handleClick', 'handleRegionSelection', 'handleReturn']);

        this.addEvents();
    }

    addEvents() {
        this.el.querySelectorAll('[data-subnavigation]').forEach((el, index) => {
            el.addEventListener('click', this.handleClick);
        });
        this.el.querySelectorAll('.region-mobile-navigation li button').forEach((el, index) => {
            el.addEventListener('click', this.handleRegionSelection);
        });
        this.el.querySelectorAll('.btn-return button').forEach((el, index) => {
            el.addEventListener('click', this.handleReturn);
        });
    }

    removeEvents() {
        this.el.querySelectorAll('[data-subnavigation]').forEach((el, index) => {
            el.removeEventListener('click', this.handleClick);
        });
        this.el.querySelectorAll('.region-mobile-navigation li a').forEach((el, index) => {
            el.removeEventListener('click', this.handleRegionSelection);
        });
        this.el.querySelectorAll('.btn-return button').forEach((el, index) => {
            el.removeEventListener('click', this.handleReturn);
        });
    }

    handleClick(e) {
        if(e.target.getAttribute("data-subnavigation")){
            this.el.querySelector('.' + e.target.getAttribute("data-subnavigation") + '-mobile-navigation').classList.add('active');
        }
    }

    handleRegionSelection(e) {
        e.preventDefault();

        let regionSlug = e.target.innerText
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim()
            .replace(/\s+/g, '-')
            .replace(/[^\w-]+/g, '')
            .replace(/--+/g, '-');

        if(regionSlug){
            this.el.querySelector('[data-region="'+ regionSlug +'"]').classList.add('active');
        }
    }

    handleReturn(e) {
        let currentParentNavigation = e.target.parentNode.parentNode.parentNode;
        currentParentNavigation.classList.remove('active');
    }

}

Me.views['MobileNavigation'] = MobileNavigation;
