import {ViewBasic} from '@quatrecentquatre/manage-me';
import {bindAll} from 'underscore';

export class ActivitySelect extends ViewBasic {
    constructor(options) {
        super(options);
        //set default variables
        this.button = this.el.querySelector('button');
        this.selectField = document.querySelector('select[name="fields[form_group_activity][]"]');
    }

    initialize() {
        bindAll(this, ['selectActivity']);
        this.addEvents();
    }

    addEvents() {
        this.button.addEventListener('click', this.selectActivity)
    }

    removeEvents() {
        this.button.removeEventListener('click', this.selectActivity)
    }

    /**
     *  This function is called when the button of an activity is clicked.
     *  It dispatches a custom event with the selected activity ID as the event detail, and then scrolls to the form.
     *  The custom event selects the activity automatically.
     */

    selectActivity() {
        const activityId = this.button.getAttribute('data-activity-id');
        document.dispatchEvent(new CustomEvent('setValues', { detail: activityId }));

        setTimeout(() => {
            const formSection = document.querySelector('#groups-form');
            formSection.scrollIntoView();
        }, 10);

    }
}

Me.views['ActivitySelect'] = ActivitySelect;
