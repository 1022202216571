import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

export class HomeHero extends ViewBasic {
    constructor(options) {
        super(options);
        // Set default variables
        this.img = this.el.querySelector('.background-wrapper img');
    }

    initialize() {
        bindAll(this, ['handleScroll']);

        this.addEvents();
    }

    addEvents() {
        window.addEventListener('scroll', this.handleScroll);

    }

    removeEvents() {
        window.removeEventListener('scroll', this.handleScroll);

    }

    handleScroll() {
        if (this.img) {
            if (!window.isMobile) {
                const scrollPosition = window.scrollY;
                gsap.to(this.img, {
                    x: scrollPosition * 0.2,
                    y: -scrollPosition * 0.10,
                })
            }
        }
    };


}

Me.views['HomeHero'] = HomeHero;
