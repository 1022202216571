import { ViewBasic } from '@quatrecentquatre/manage-me';
import {bindAll} from "underscore";

export class CardForm extends ViewBasic{
    constructor(options) {
        super(options);
        //set default variables;
        this.cardInputs = this.el.querySelectorAll('input[type="radio"]');
        this.giftBtn = this.el.querySelector('#gift-button');
        this.bookBtn = this.el.querySelector('#booking-button');
    }

    async initialize() {
        bindAll(this, ['open', 'initSquare', 'clickHandler', 'sendTokens', 'resizeHandler']);
        this.addEvents();
        await this.initSquare();
        if (this.cardInputs[0]) {
            this.cardInputs[0].click();
        }
    }

    addEvents() {
        let scope = this;
        window.addEventListener('payment.step-3.open', this.open);
        this.cardInputs.forEach((input) => {
            input.addEventListener('click', scope.clickHandler)
        });
        if(this.giftBtn) {
            this.giftBtn.addEventListener('click', this.sendTokens);
        }
        if (this.bookBtn) {
            this.bookBtn.addEventListener('click', this.sendTokens);
        }
        window.addEventListener('resize', this.resizeHandler);
    }

    removeEvents() {
        let scope = this;
        window.removeEventListener('payment.step-3.open', this.open);
        this.cardInputs.forEach((input) => {
            input.removeEventListener('click', scope.clickHandler)
        });
        if(this.giftBtn) {
            this.giftBtn.removeEventListener('click', this.sendTokens);
        }
        if (this.bookBtn) {
            this.bookBtn.removeEventListener('click', this.sendTokens);
        }
        window.addEventListener('resize', this.resizeHandler);
    }

    open() {
        if(!this.el.parentElement.classList.contains('active')){
            this.el.querySelector('.accordion-title').click()
        }
    }

    async initSquare() {
        if (this.el.querySelector('#card-container')) {
            let scope = this;
            let currentLocale = document.documentElement.getAttribute('lang') + '-CA';

            if(window.SETTINGS.SQUARE_APPLICATION_ID && this.params.locationId){
                const payments = Square.payments(window.SETTINGS.SQUARE_APPLICATION_ID, this.params.locationId);
                payments.setLocale(currentLocale);
                const card = await payments.card({
                    style : {
                        '.input-container': {
                            borderRadius: '30px',
                        },
                    }
                });
                await card.attach('#card-container');

                const cardButton = document.getElementById('card-button');
                cardButton.addEventListener('click', async () => {
                    const statusContainer = document.getElementById('payment-status-container');

                    try {
                        const result = await card.tokenize();
                        if (result.status === 'OK') {
                            scope.sendTokens(result.token);
                        } else {
                            let errorMessage = `Tokenization failed with status: ${result.status}`;
                            if (result.errors) {
                                errorMessage += ` and errors: ${JSON.stringify(
                                    result.errors
                                )}`;
                            }

                            throw new Error(errorMessage);
                        }
                    } catch (e) {
                        console.error(e);
                    }
                });
            }
        }
    }

    clickHandler(e) {
        let scope = this;
        if(!e.currentTarget.parentElement.parentElement.classList.contains('is-open')){
            this.cardInputs.forEach((input) => {
               input.parentElement.parentElement.classList.remove("is-open");
               let content = input.parentElement.parentElement.querySelector('.content')
               content.style.maxHeight = null;
            });

            e.currentTarget.parentElement.parentElement.classList.add("is-open");
            let content = e.currentTarget.parentElement.parentElement.querySelector('.content');
            content.style.maxHeight = content.scrollHeight + "px";
        }
    }

    resizeHandler() {
        let currentOpenAccordion = this.el.querySelector('.is-open');
        if(currentOpenAccordion) {
            let content = currentOpenAccordion.querySelector('.content');
            content.style.maxHeight = content.scrollHeight + "px";
        }
    }

    sendTokens(token) {
        let paymentTypeValue = this.el.querySelector('[name="fields[payment][type]"]:checked') ? this.el.querySelector('[name="fields[payment][type]"]:checked').value : 'NULL';

        window.dispatchEvent(new CustomEvent('payment.set-payment-infos', {
            detail:{
                'fields[payment][type]': paymentTypeValue,
                site_csrf: this.el.querySelector('[name="site_csrf"]').value,
                square_token: token
            }
        }));
    }
}

Me.views['CardForm'] = CardForm;
