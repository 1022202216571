import {SwiperTiles} from './tiles';
import Swiper from "swiper";
import {bindAll} from 'underscore';

export class ActivityTiles extends SwiperTiles {
    constructor(options) {
        super(options);
        this.swiper;

    }

    initialize() {
        bindAll(this, [
            'swiperControl',
            'transitionEnd',
            'transitionStart',
            'breakpointChangedHandler'
        ]);

        this.prevArrows = this.el.querySelectorAll('.prev-arrow .arrow-controls');
        this.nextArrows = this.el.querySelectorAll('.next-arrow .arrow-controls');
        this.tiles = this.el.querySelectorAll('.tile');

        super.initialize();
        this.transitionEnd();
    }

    addEvents() {
        let scope = this;
        super.addEvents();
        window.addEventListener('breakpointChanged', this.breakpointChangedHandler);
        this.swiper.on('transitionEnd', this.transitionEnd);
        this.swiper.on('touchEnd', this.transitionEnd);
        this.swiper.on('transitionStart', this.transitionStart);
        this.swiper.on('touchMove', this.transitionStart);
    }

    removeEvents() {
        let scope = this;
        super.removeEvents();
        window.removeEventListener('breakpointChanged', this.breakpointChangedHandler);
        this.swiper.off('transitionEnd', this.transitionEnd);
        this.swiper.off('touchEnd', this.transitionEnd);
        this.swiper.off('transitionStart', this.transitionStart);
        this.swiper.off('touchMove', this.transitionStart);
    }

    breakpointChangedHandler() {
        this.transitionEnd();
    }

    transitionStart() {
        this.tiles.forEach((tile) => {
            tile.classList.remove('show-prev-arrow', 'show-next-arrow', 'is-end');
        });
    }

    transitionEnd() {
        this.tiles.forEach((tile) => {
            tile.classList.remove('show-prev-arrow', 'show-next-arrow', 'is-end');
        });

        if (this.swiper.activeIndex !== 0) {
            if (!this.swiper.isEnd) {
                if (window.innerWidth >= 768 && window.innerWidth < 1024) {
                    this.tiles[this.swiper.activeIndex + 1].classList.add('show-prev-arrow');
                } else if (window.innerWidth >= 1024) {
                    this.tiles[this.swiper.activeIndex].classList.add('show-prev-arrow');
                }
                this.tiles[this.swiper.activeIndex + 3].classList.add('show-next-arrow');
            } else {
                if (window.innerWidth >= 768 && window.innerWidth < 1024) {
                    this.tiles[this.swiper.activeIndex].classList.add('show-prev-arrow', 'is-end');
                } else if (window.innerWidth >= 1024) {
                    this.tiles[this.swiper.activeIndex - 1].classList.add('show-prev-arrow', 'is-end');
                }
            }
        } else {
            this.tiles[this.swiper.activeIndex + 3].classList.add('show-next-arrow');
        }
    }

    swiperControl() {
        this.swiper = new Swiper(this.newSwiper, {
            slidesPerView: 1.10,
            spaceBetween: 20,
            navigation: {
                prevEl: '.prev-arrow .arrow-controls',
                nextEl: '.next-arrow .arrow-controls',
            },
            breakpoints: {
                768: {
                    slidesPerView: 2.5,
                    spaceBetween: 40,
                },
                1024: {
                    slidesPerView: 3.4375,
                    spaceBetween: 40,
                }
            },
        });
    }
}

Me.views['ActivityTiles'] = ActivityTiles;
