import {ViewBasic} from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore'
import Choices from 'choices.js';
import 'choices.js/src/styles/choices.scss'

export class Selects extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['changeHandler', 'destroyChoices', 'setValuesByChoice', 'destroySpecificChoice', 'openSelect']);
        this.wrapper = this.el.parentElement.parentElement.parentElement;
        this.selectWrapper = this.el.parentElement.parentElement;
        this.noValues = this.wrapper.querySelector('.no-choices');

        this.choices = new Choices(this.el, {
            shouldSort: false,
            removeItemButton: true,
            searchEnabled: false,
            searchChoices: false,
            position: 'bottom',
            allowHTML: true,
            noChoicesText: this.noValues.textContent
        });

        this.choices.setChoiceByValue('');

        // CHECK IF THE CURRENT SELECT HAS AN PARAMS SAME AS NAME AND PRESELECT VALUE
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search.slice(1));
        if(params) {
            let currentName = this.el.name;
            let currentSelectParam = params.get(currentName);
            if (currentSelectParam){
                this.choices.setChoiceByValue(currentSelectParam);
                this.el.parentElement.parentElement.classList.add('has-value');
            }
        }

        // IF THE CURRENT SELECT HAS A DEFAULT VALUE, SET IT
        if(this.el.getAttribute('data-default-value')){
            this.choices.setChoiceByValue(this.el.getAttribute('data-default-value'));
        }

        // Make select visible when loaded
        this.selectWrapper.style.opacity = 1;

        this.addEvents();
    }

    addEvents() {
        this.el.addEventListener('change', this.changeHandler);
        this.el.addEventListener('showDropdown', this.openSelect);
        document.addEventListener('resetSelect', this.destroyChoices);
        document.addEventListener('setValues', this.setValuesByChoice);
        document.addEventListener('destroySpecificChoice', this.destroySpecificChoice);
    }

    removeEvents() {
        this.el.removeEventListener('change', this.changeHandler);
        this.el.removeEventListener('showDropdown', this.openSelect);
        document.removeEventListener('resetSelect', this.destroyChoices);
        document.removeEventListener('setValues', this.setValuesByChoice);
        document.removeEventListener('destroySpecificChoice', this.destroySpecificChoice);
    }

    changeHandler(e) {
        if(this.el.value) {
            this.el.parentElement.parentElement.classList.add('has-value');
            this.el.classList.remove('error');

            // Remove error message and error style if a value has been added.s
            if(this.wrapper.classList.contains('has-error')){
                this.wrapper.classList.remove('has-error')
                this.wrapper.querySelector('.error-msg').classList.add('hide')
            }
        } else if (!this.choices.getValue(true) || this.choices.getValue(true).length === 0) {
            this.el.parentElement.parentElement.classList.remove('has-value');
        } else {
            this.el.parentElement.parentElement.classList.add('has-value');
            this.el.classList.remove('error');

            // Remove error message and error style if a value has been added.s
            if(this.wrapper.classList.contains('has-error')){
                this.wrapper.classList.remove('has-error')
                this.wrapper.querySelector('.error-msg').classList.add('hide')
            }
        }
    }

    /**
     *
     * @param e
     * Selects the activity based on the provided activityId from the activity button.
     * Add highlight css class to the corresponding activity in the dropdown.
     * Dispatches sortParks event which sorts available parks for the activity.
     * Calls the changeHandler function, which adds the has-value class to the field.
     */

    setValuesByChoice(e){
        e.preventDefault();

        const activityId = e.detail;
        this.choices.setChoiceByValue(activityId)

        this.el.parentElement.parentElement.querySelectorAll('.choices__list--dropdown .choices__item--selectable').forEach((entry) => {
            if(entry.getAttribute('data-value') === activityId){
                entry.classList.add('is-highlighted');
            }
        });

        document.dispatchEvent(new CustomEvent('sortParks'));
        this.changeHandler();
    }

    /**
     * Destroys the multiselect values on form success. This empties the field and fills it back.
     * This function is only called on ajaxSuccess
     */
    destroyChoices(){
        this.choices.destroy();
        this.choices.init();
    }

    /**
     * Destroys the multiselect values on form success. This empties the field and fills it back.
     * This function is only called on ajaxSuccess
     */
    destroySpecificChoice(e){
        if(e.detail.element.id === this.choices.passedElement.element.id){
            this.choices.destroy();
        }
    }


    openSelect() {
        let currentListDropdown = this.el.parentElement.parentElement.querySelector('.choices__list--dropdown');
        currentListDropdown.setAttribute('data-lenis-prevent', true);
    }
}

Me.views['Selects'] = Selects;
