import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore';


export class TilesLoadMore extends ViewBasic{
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['loadMore', 'handleResize']);
        this.addEvents();

        this.mobile = window.SETTINGS.BREAKPOINTS.SM_MAX;
        this.lists = this.el.querySelectorAll('.list');


        this.lists.forEach((list) => {
            this.tiles = list.querySelectorAll('.tile');
            this.button = list.parentElement.querySelector('.load-more button');
            this.tilesNumber = list.dataset.tiles;
            this.hiddenTiles = Array.from(this.tiles).slice(this.tilesNumber)

            if(window.innerWidth <= this.mobile) {
                this.hiddenTiles.forEach(tile => {tile.classList.add('hide')})
                if(this.hiddenTiles.length) this.button.parentElement.classList.remove('hide')
            }

        })
    }

    addEvents() {
        // window.addEventListener('resize', this.handleResize);
        this.el.querySelector('.load-more').addEventListener('click', this.loadMore)
    }

    removeEvents() {
        // window.removeEventListener('resize', this.handleResize);
        this.el.querySelector('.load-more').removeEventListener('click', this.loadMore)
    }

    handleResize(){
        // If in mobile, hide all tiles and show button to load more
        if(window.innerWidth <= this.mobile){
            this.hiddenTiles.forEach(tile => {tile.classList.add('hide')})
            if(this.hiddenTiles.length) this.button.parentElement.classList.remove('hide')
        } else{
            this.hiddenTiles.forEach(tile => {tile.classList.remove('hide')})
            if(this.hiddenTiles.length) this.button.parentElement.classList.add('hide')
        }

    }

    /**
     * Load more results and hide button
     * @param e
     */

    loadMore(e){
        e.preventDefault();
        let index = 0;

        const closestList = e.target.parentElement.previousElementSibling;

        closestList.querySelectorAll('.tile.hide').forEach(function(element){
            index++;
            element.classList.remove('hide');
        });

        e.target.parentElement.classList.add('hide');
    }
}

Me.views['TilesLoadMore'] = TilesLoadMore;
