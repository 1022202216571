import {ViewBasic} from '@quatrecentquatre/manage-me';
import {bindAll} from 'underscore';

export class InputNumber extends ViewBasic {
    constructor(options) {
        super(options);

        this.subBtn = this.el.querySelector('.btn-sub');
        this.addBtn = this.el.querySelector('.btn-add');
        this.input = this.el.querySelector('input');
        this.number = this.el.querySelector('.number');

        this.inputMin = parseInt(this.input.getAttribute('min'));
        this.inputMax = parseInt(this.input.getAttribute('max'));

        if(this.input.value > 0) {
            this.subBtn.classList.remove('disabled');
        }
        if(this.input.value == this.inputMin){
            this.subBtn.classList.add('disabled');
        }
        if(this.input.value == this.inputMax){
            this.addBtn.classList.add('disabled');
        }
    }

    initialize() {
        bindAll(this, ['substraction','addition', 'handleChange']);
        this.addEvents();
        this.handleChange();
    }

    addEvents() {
        this.subBtn.addEventListener('click', this.substraction);
        this.addBtn.addEventListener('click', this.addition);
        this.input.addEventListener('change', this.handleChange);
    }
    removeEvents() {
        this.subBtn.removeEventListener('click', this.substraction);
        this.addBtn.removeEventListener('click', this.addition);
        this.input.addEventListener('change', this.handleChange);
    }

    handleChange(){
        this.number.innerHTML = this.input.value;
    }

    substraction(e) {
        e.preventDefault();
        e.stopPropagation();

        if(this.input.value > this.inputMin) {
            this.input.value--;
            this.input.dispatchEvent(new Event('change'));
            this.addBtn.classList.remove('disabled');

            if(parseInt(this.input.value) === parseInt(this.inputMin)){
                this.subBtn.classList.add('disabled');
            }
        } else {
            this.input.value = this.inputMin;
        }
    }

    addition(e) {
        e.preventDefault();
        e.stopPropagation();

        if(this.input.value < this.inputMax) {
            this.input.value++;
            this.input.dispatchEvent(new Event('change'));
            this.subBtn.classList.remove('disabled');

            if(parseInt(this.input.value) === parseInt(this.inputMax)){
                this.addBtn.classList.add('disabled');
            }
            if(parseInt(this.input.value) === parseInt(this.inputMin)){
                this.subBtn.classList.add('disabled');
            }
        } else {
            this.input.value = this.inputMax;
        }
    }
}

Me.views['InputNumber'] = InputNumber;
