import { ViewBasic } from '@quatrecentquatre/manage-me';
import {bindAll} from "underscore";

export class PaymentForm extends ViewBasic{
    constructor(options) {
        super(options);
        this.paymentInformations = new FormData();
    }

    async initialize() {
        bindAll(this, ['setData', 'sendData']);
        this.addEvents();
    }

    addEvents() {
        window.addEventListener('payment.set-infos', this.setData);
        window.addEventListener('payment.set-conditions', this.setData);
        window.addEventListener('payment.set-payment-infos', this.setData);
    }

    removeEvents() {
        window.removeEventListener('payment.set-infos', this.setData);
        window.removeEventListener('payment.set-conditions', this.setData);
        window.removeEventListener('payment.set-payment-infos', this.setData);
    }

    setData(e) {
        let scope = this;
        let data = e.detail;
        Object.keys(data).forEach(function(key) {
            if(scope.paymentInformations.has(key)) {
                scope.paymentInformations.set(key, data[key]);
            } else {
                scope.paymentInformations.append(key, data[key]);
            }
        });

        if(e.type === 'payment.set-payment-infos') {
            this.sendData();
        }
    }

    sendData() {
        let form = this.el.querySelector('.payment-form');
        for(var entry of this.paymentInformations.entries()) {
            let newField = document.createElement('input');
            newField.setAttribute('type','hidden');
            newField.setAttribute('name',entry[0]);
            newField.setAttribute('value',entry[1]);
            form.appendChild(newField);
        }
        form.submit();
    }
}

Me.views['PaymentForm'] = PaymentForm;
