import {ViewBasic} from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore';
import { SplitText } from 'gsap/SplitText';
import { gsap } from 'gsap';

export class Button extends ViewBasic {
    constructor(options) {
        super(options);

        if(!this.el.classList.contains('no-animation')){
            let mySplitText = new SplitText(this.el);
            this.chars = mySplitText.chars;

            if(this.el.querySelector('span[class^="icon-"]')){
                this.chars.push(this.el.querySelector('span[class^="icon-"]'));
            }
        }
    }

    initialize() {
        bindAll(this, ['clearTimeline','initTimeline','hoverHandler']);
        this.addEvents();

        if(!this.el.classList.contains('no-animation')){
            this.initTimeline();
        }
    }

    addEvents() {
        if(!this.el.classList.contains('no-animation')){
            this.el.addEventListener('mouseenter', this.hoverHandler);
        }
    }

    removeEvents() {
        if(!this.el.classList.contains('no-animation')){
            this.el.removeEventListener('mouseenter', this.hoverHandler);
        }
    }

    clearTimeline(){
        if(this.tl){
            this.tl.pause(0).kill(true);
        }
    }

    initTimeline() {
        this.clearTimeline();

        let scope = this;
        this.tl = gsap.timeline({paused: true});
        this.tl.to(scope.chars, {duration:.3,y:-20,stagger:.04,ease:"Power1.easeInOut"});
        this.tl.from(scope.chars, {duration:.3,y:20,stagger:.04,ease:"Power1.easeInOut"}, 0.3);
        gsap.set(scope.chars, {y: 0});
    }

    hoverHandler() {
        if(!window.isMobile && !window.isTablet) {
            this.tl.play(0);
        }
    }
}

Me.views['Button'] = Button;
