import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore';

export class ParkHeader extends ViewBasic{
    constructor(options) {
        super(options);
        //set default variables
    }

    initialize() {
        bindAll(this, ['scrollHandler', 'showHideMenu']);
        this.lastScrollTop = 0;

        this.addEvents();
    }

    addEvents() {
        document.addEventListener('scroll', this.scrollHandler);
    }

    removeEvents() {
        document.removeEventListener('scroll', this.scrollHandler);
    }

    /**
     * Snap header when scrolling
     * @param e: scroll event
     */
    scrollHandler(e) {
        const element = this.el.querySelector('.park-primary-navigation');
        const trigger = this.el.previousElementSibling;

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                element.classList.remove("fixed");
                this.el.style.height = '';
                document.querySelector('.primary-navigation').classList.remove('force-hide')
            } else {
                let scrollTop = document.documentElement.scrollTop;
                if(scrollTop > this.el.offsetTop) {
                    element.classList.add("fixed");
                    this.el.style.height = element.offsetHeight + 'px';
                    document.querySelector('.primary-navigation').classList.add('force-hide');
                }
            }
        });

        observer.observe(trigger);
    }

    showHideMenu(scroll) {
        if (scroll === 'down') {
            this.el.querySelector('.park-primary-navigation').classList.add('hide-up');
        } else if (scroll === 'up') {
            if(this.el.querySelector('.park-primary-navigation').classList.contains('hide-up')) {
                this.el.querySelector('.park-primary-navigation').classList.remove('hide-up');
            }
        }
    }
}

Me.views['ParkHeader'] = ParkHeader;
