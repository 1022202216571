import { SwiperTiles } from './tiles';
import Swiper from "swiper";

export class InstagramTiles extends SwiperTiles {
    constructor(options) {
        super(options);
    }

    swiperControl() {
        const swiper = new Swiper(this.newSwiper, {
            slidesPerView: 1.5,
            spaceBetween: 10,
            navigation: {
                nextEl: '.swiper-next',
                prevEl: '.swiper-prev',
            },
            breakpoints: {
                768: {
                    slidesPerView: 2.5,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 2.5,
                    spaceBetween: 42.5,
                },
                1920: {
                    slidesPerView: 3.5,
                    spaceBetween: 50,
                }
            },
        });
    }
}

Me.views['InstagramTiles'] = InstagramTiles;
