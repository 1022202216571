import { object, boolean } from 'yup';
import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from "underscore";

export class ConditionsForm extends ViewBasic {
    constructor(options) {
        super(options);

        this.button = this.el.querySelector('[type="submit"]')
        this.form = this.el.querySelector('form')

        this.formFields = [
            {
                name: 'accepted_conditions',
                validationSchema: boolean().oneOf([true]).required(),
            }
        ];

        let fields = {};
        this.formFields.forEach((value, key) => fields[value.name] = value.validationSchema);
        this.schema = object().shape(fields);
    }

    initialize() {
        bindAll(this, ['submitHandler', 'handleValidationSuccess', 'handleValidationError', 'setInfos', 'open']);
        this.addEvents();
    }

    addEvents() {
        this.button.addEventListener('click', this.submitHandler);
        window.addEventListener('payment.step-2.open', this.open);
    }

    removeEvents() {
        this.button.removeEventListener('click', this.submitHandler);
        window.removeEventListener('payment.step-2.open', this.open);

    }

    open() {
        if (!this.el.parentElement.classList.contains('active')){
            this.el.querySelector('.accordion-title').click();
        }
    }

    submitHandler(e) {
        e.preventDefault();

        let formData = new FormData(this.form);
        let formDataObj = {};
        formData.forEach((value, key) => (formDataObj[key] = value));

        let scope = this;
        this.schema
            .validate(formDataObj, {abortEarly: false})
            .then(value => {
                scope.handleValidationSuccess(value);
            })
            .catch(err => {
                scope.handleValidationError(err);
            });
    }

    handleValidationSuccess(value) {
        let scope = this;
        this.formFields.forEach(field => {
            scope.el.querySelector('[name="'+ field.name +'"]').classList.remove('has-error');
            let currentErrorFieldMsg = scope.el.querySelector('.error-msg');
            currentErrorFieldMsg.classList.add('hide');
            currentErrorFieldMsg.setAttribute("aria-hidden", !0);
        });
       this.setInfos(value);
    }

    handleValidationError(err) {
        let scope = this;
        JSON.parse(JSON.stringify(err)).inner.forEach(error => {
            scope.el.querySelector('[name="'+ error.path +'"]').classList.add('has-error');
            let currentErrorFieldMsg = scope.el.querySelector('.error-msg');
            currentErrorFieldMsg.classList.remove('hide');
            currentErrorFieldMsg.setAttribute("aria-hidden", !1);
        });
        window.dispatchEvent(new Event('resize'));
    }

    setInfos() {
        // Display accepted mention after form submit
        this.el.querySelector('.done').classList.remove('hide');

        // Close current Accordion
        this.el.querySelector('.done').parentElement.click();
        this.el.querySelector('.accordion-trigger').classList.remove('hide');

        // Call to next step to open
        window.dispatchEvent(new CustomEvent('payment.step-3.open'));

        // Call to page JS to save infos
        window.dispatchEvent(new CustomEvent('payment.set-conditions', {detail: {accepted_conditions: true}}));
    }
}

Me.views['ConditionsForm'] = ConditionsForm;
