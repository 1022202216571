import {ViewBasic} from '@quatrecentquatre/manage-me';
import {bindAll} from 'underscore';
import { FiltersFormService } from '../../services/filters-form';


export class SamePagePagination extends ViewBasic {
    constructor(options) {
        super(options);

        // FiltersFormService for fetch.
        this.service = new FiltersFormService();
    }

    initialize() {
        bindAll(this, ['clickHandler']);
        this.addEvents();
    }

    addEvents() {
        this.el.querySelectorAll('.pagination a').forEach((link) => {
            link.addEventListener('click', this.clickHandler);
        });
    }

    removeEvents() {
        this.el.querySelectorAll('.pagination a').forEach((link) => {
            link.removeEventListener('click', this.clickHandler);
        });
    }

    clickHandler(e) {
        if (e){
            e.preventDefault();
            e.stopPropagation();
        }

        let scope = this;
        let updatingSection = document.querySelector(scope.params.selector)
        updatingSection.classList.add('ajax-loading');

        this.service.getFiltersResutls(e.currentTarget.href)
            .then(response => {
                updatingSection.classList.remove('ajax-loading');
                if(response.errors){
                    // form submit successfully, but errors returned
                    console.log(response.errors)
                }else{
                    let updatingNumberText = document.querySelector('[data-nb-items]')
                    updatingSection.classList.add('in-transition');

                    //success
                    let html;
                    if (!window.DOMParser) return false;
                    let parser = new DOMParser();
                    try {
                        html = parser.parseFromString(response, 'text/html');
                    } catch(err) {
                        return false;
                    }

                    setTimeout(function (){
                        if(updatingNumberText){
                            updatingNumberText.innerHTML = html.querySelector('[data-nb-items]').innerHTML;
                        }
                        updatingSection.innerHTML = html.querySelector(scope.params.selector).innerHTML;
                        updatingSection.classList.remove('in-transition');


                        scope.removeEvents();
                        scope.addEvents();
                    },200);
                }
            })
            .catch(error => {
                //PHP error
                scope.el.classList.add('error-php');
            });
    }
}

Me.views['SamePagePagination'] = SamePagePagination;
