import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore';
import gsap from "gsap";

export class Header extends ViewBasic{
    constructor(options) {
        super(options);
        //set default variables

        this.isSubNavOpen = false;
    }

    initialize() {
        bindAll(this, ['handleRegionSelection','handleSubnavigation','resizeHandler','scrollHandler', 'handleMobileMenu', 'showHideMenu', 'openSubnavigation', 'closeCurrentSubnavigation', 'closeMobileNavigation']);
        this.lastScrollTop = 0;

        this.desktopTween;
        this.mobileTween;
        this.mobileLight;

        if(this.el.classList.contains('light')) this.lightMode = true

        this.resizeHandler();
        this.addEvents();
    }

    addEvents() {
        window.addEventListener('resize', this.resizeHandler);
        window.addEventListener('hideMenu', this.showHideMenu);

        document.addEventListener('scroll', this.scrollHandler);
        this.el.querySelector('.mobile-menu-trigger').addEventListener('click', this.handleMobileMenu);
        this.el.querySelectorAll('.has-subnavigation').forEach((el, index) => {
            el.addEventListener('mouseenter', this.handleSubnavigation);
        });
        this.el.querySelectorAll('.primary-navigation-wrapper>li:not(.has-subnavigation)').forEach((el, index) => {
            el.addEventListener('mouseenter',  this.closeCurrentSubnavigation);
        });
        this.el.addEventListener('mouseleave', this.handleSubnavigation);
        this.el.querySelectorAll('.parks-subnavigation .links li button').forEach((el, index) => {
            el.addEventListener('click', this.handleRegionSelection);
        });
    }

    removeEvents() {
        window.removeEventListener('resize', this.resizeHandler);
        window.removeEventListener('hideMenu', this.showHideMenu);

        document.removeEventListener('scroll', this.scrollHandler);
        this.el.querySelector('.mobile-menu-trigger').removeEventListener('click', this.handleMobileMenu);
        this.el.querySelectorAll('.has-subnavigation').forEach((el, index) => {
            el.removeEventListener('mouseenter', this.handleSubnavigation);
        });
        this.el.querySelectorAll('.primary-navigation-wrapper>li:not(.has-subnavigation)').forEach((el, index) => {
            el.removeEventListener('mouseenter',  this.closeCurrentSubnavigation);
        });
        this.el.removeEventListener('mouseleave', this.handleSubnavigation);
        this.el.querySelectorAll('.parks-subnavigation .links li button').forEach((el, index) => {
            el.removeEventListener('click', this.handleRegionSelection);
        });
    }

    /**
     * Snap header when scrolling
     * @param e: scroll event
     */
    scrollHandler(e) {
        const element = this.el.querySelector('.primary-navigation');
        const trigger = this.el.querySelector('.primary-navigation').previousElementSibling;


        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                element.classList.remove("fixed");
                if(this.lightMode && !this.isSubNavOpen){
                    this.el.classList.add('light')
                }
            } else {
                let scrollTop = document.documentElement.scrollTop;
                if (scrollTop > this.lastScrollTop) {
                    this.showHideMenu(e,'down');
                } else if (scrollTop < this.lastScrollTop) {
                    this.showHideMenu(e,'up');
                }
                this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;

                if(scrollTop > this.el.offsetHeight) {
                    element.classList.add("fixed");
                    if(this.lightMode){
                        this.el.classList.remove('light')
                    }
                }
            }
        });

        observer.observe(trigger);
    }

    showHideMenu(e, scroll) {
        if (scroll === 'down' || e.type === 'hideMenu') {
            this.el.querySelector('.primary-navigation').classList.add('hide-up');
        } else if (scroll === 'up') {
            if(this.el.querySelector('.primary-navigation').classList.contains('hide-up')) {
                this.el.querySelector('.primary-navigation').classList.remove('hide-up');
            }
        }
    }

    resizeHandler() {
        if (!window.isTablet) {
            this.el.style.height = `${this.el.clientHeight}px`;
            this.closeMobileNavigation()
        } else {
            this.el.style.height = null;
            this.closeCurrentSubnavigation();
        }
    }

    handleSubnavigation(e) {
        if(e){
            if(e.type === "mouseenter") {
                if(!this.el.querySelector('[data-name="'+ e.target.getAttribute('data-subnavigation') +'"]').classList.contains('open')){
                    this.closeCurrentSubnavigation();
                    this.openSubnavigation(e);

                }
            } else if(e.type === "mouseleave") {
                this.closeCurrentSubnavigation();
            }
        }
    }

    openSubnavigation(e) {
        let scope = this;
        let currentSubNav = this.el.querySelector('[data-name="'+ e.target.getAttribute('data-subnavigation') +'"]');
        currentSubNav.classList.remove('closed');
        currentSubNav.classList.add('open');

        this.isSubNavOpen = true;
        this.el.classList.remove('light')

        this.el.classList.add('opened-subnavigation')

        document.querySelector('html').classList.add('no-scroll');
        window.lenis.stop();

        // COLUMN ANIMATION
        currentSubNav.querySelectorAll('.subnavigation-column').forEach(function(element, index){
            scope.desktopTween = gsap.to(element,{y:0, opacity:1, duration: 1.6, delay:0.4*(index/10+1), ease: "power4.out"});

            if (index === scope.el.querySelectorAll('[data-name="'+ e.target.getAttribute('data-subnavigation') +'"] .links li').length - 1){
                let imageContainerDelay = 1;
                if (scope.el.querySelector('[data-name="'+ e.target.getAttribute('data-subnavigation') +'"] .park-links-container')){
                    scope.desktopTween = gsap.to(scope.el.querySelector('[data-name="'+ e.target.getAttribute('data-subnavigation') +'"] .park-links-container'),{y:0, opacity:1, duration: 1.6, delay:0.6*((index+1)/10+1), ease: "power4.out"});
                    imageContainerDelay = 2;
                }
                scope.desktopTween = gsap.to(scope.el.querySelector('[data-name="'+ e.target.getAttribute('data-subnavigation') +'"] .image-container'),{y:0, opacity:1, duration: 1.6, delay:0.6*((index+imageContainerDelay)/10+1), ease: "power4.out"});
            }
        });
    }

    closeCurrentSubnavigation() {
        let scope = this;
        if(this.el.querySelector('.subnavigation.open')){
            // COLUMN ANIMATION
            this.el.querySelector('.subnavigation.open').querySelectorAll('.subnavigation-column').forEach(function(element, index){
                scope.desktopTween = gsap.to(element,{y:-30, opacity:'0', duration: 0.4});
                if (index === scope.el.querySelectorAll('.subnavigation.open .links li').length - 1){
                    if (scope.el.querySelector('.subnavigation.open .park-links-container')){
                        scope.desktopTween = gsap.to(scope.el.querySelector('.subnavigation.open .park-links-container'),{y:-30, opacity:'0', duration: 0.4});
                    }
                    scope.desktopTween = gsap.to(scope.el.querySelector('.subnavigation.open .image-container'),{y:-30, opacity:'0', duration: 0.4});
                }
            });

            this.el.querySelector('.subnavigation.open').classList.add('closed');
            this.el.querySelector('.subnavigation.open').classList.remove('open');
            this.el.classList.remove('opened-subnavigation')
            document.querySelector('html').classList.remove('no-scroll');
            window.lenis.start();
            if(this.lightMode && !this.el.querySelector('.primary-navigation.fixed')) this.el.classList.add('light');
            this.isSubNavOpen = false;
        }
    }

    handleRegionSelection(e) {
        if(e){
            e.preventDefault();
                // This only allows one active region at a time.
                const activeButton = this.el.querySelector('.parks-subnavigation .links li button.active');
                if (activeButton && activeButton !== e.target) {
                    activeButton.classList.remove('active');
                }
                if(!e.target.classList.contains('active')){
                this.el.querySelectorAll('.parks-subnavigation .links li a').forEach((el, index) => {
                    el.classList.remove('active');
                });
                e.target.classList.add('active');
            }
            if(this.el.querySelector('[data-region].active')){
                this.el.querySelector('[data-region].active').classList.remove('active');
            }

            const regions = this.el.querySelectorAll('[data-region]');

            // Find and activate the matching region element (case-insensitive)
            for (const region of regions) {
                if (region.getAttribute('data-region').trim().toLowerCase() === e.target.innerText.trim().toLowerCase()) {
                    region.classList.add('active');
                    break; // Once found, exit the loop
                }
            }
        }
    }

    handleMobileMenu(e) {
        let scope = this;

        if(!this.el.querySelector('.mobile-navigation').classList.contains('open')) {
            window.lenis.stop();
            document.querySelector('html').classList.add('no-scroll');
            this.el.querySelector('.mobile-navigation').classList.add('open');
            this.el.querySelector('.primary-navigation').classList.add('opened-mobile-menu');
            this.el.classList.add('opened-mobile-menu');
            if(this.lightMode && this.el.classList.contains('light')){
                this.mobileLight = true;
                this.el.classList.remove('light')
            }

            this.el.querySelectorAll('.mobile-navigation .primary-mobile-navigation li').forEach(function(element, index){
                scope.mobileTween = gsap.to(element,{opacity:'1', duration: 0.2, delay:0.4*(index/10+1)});
            });

        } else {
            this.closeMobileNavigation()
        }
    }

    closeMobileNavigation() {
        let scope = this;
        if(this.el.querySelector('.mobile-navigation').classList.contains('open')){
            window.lenis.start();
            document.querySelector('html').classList.remove('no-scroll');
            this.el.querySelector('.mobile-navigation').classList.remove('open');
            this.el.querySelector('.primary-navigation').classList.remove('opened-mobile-menu');
            this.el.classList.remove('opened-mobile-menu');
            if(this.el.querySelector('.mobile-subnavigation.active')){
                this.el.querySelector('.mobile-subnavigation.active').classList.remove('active');
            }
            if(this.lightMode && this.mobileLight) this.el.classList.add('light');

            this.el.querySelectorAll('.mobile-navigation .primary-mobile-navigation li').forEach(function(element, index){
                scope.mobileTween = gsap.to(element,{opacity:'0', duration: 0.2});
            });
        }
    }

}

Me.views['Header'] = Header;
