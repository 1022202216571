import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore';
import {MobileNavigation} from "./mobile-navigation";
export class FileInput extends ViewBasic{
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['changeHandler', 'focusHandler', 'blurHandler', 'cancelUpload']);
        this.wrapper = this.el.parentElement.parentElement;
        this.addEvents();
    }

    addEvents() {
        this.el.addEventListener('change', this.changeHandler);
        this.el.addEventListener('focus', this.focusHandler);
        this.el.addEventListener('blur', this.blurHandler);
        // event to remove the file added to the upload field - need to fix bug
        this.el.parentElement.querySelector('button.remove').addEventListener('click', this.cancelUpload);


    }

    removeEvents() {
        this.el.removeEventListener('change', this.changeHandler);
        this.el.removeEventListener('focus', this.focusHandler);
        this.el.removeEventListener('blur', this.blurHandler);
    }

    changeHandler(e) {
        const fileInput = e.target;
        const fileNameInput = this.el.parentElement.querySelector('.file-name');

        // if value is empty or upload file is undefined (happens when upload is canceled)
        // empty last fileNameInput
        if(this.el.value === '' || typeof fileInput.files[0] === 'undefined'){
            fileNameInput.textContent = '';
            this.el.classList.remove('has-value');
            this.el.parentElement.querySelector('button.remove').classList.add('hide')
        }
        else{
            this.el.classList.add('has-value');
            this.el.classList.remove('error');

            // if file is uploaded, remove error message and class.
            if(this.wrapper.classList.contains('has-error')){
                this.wrapper.classList.remove('has-error')
                this.el.classList.remove('has-error')
                this.wrapper.querySelector('.error-msg').classList.add('hide')
            }

            this.el.parentElement.querySelector('button.remove').classList.remove('hide')
            fileNameInput.textContent = fileInput.files[0].name;
        }
    }
    focusHandler(e){
        // add delay before showing value
        setTimeout(() => {
            this.el.classList.add('has-value');
        }, 3500)
    }

    blurHandler(e){
        if(this.el.value === ''){
            this.el.classList.remove('has-value');
        }
    }

    cancelUpload() {
        const fileNameInput = this.el.parentElement.querySelector('.file-name');
        this.el.value = ''; // Reset the file input to clear the selection
        fileNameInput.textContent = '';
        this.el.classList.remove('has-value');
        this.el.classList.remove('error');
        this.el.parentElement.querySelector('button.remove').classList.add('hide')

        // if file is removed, remove error message and class.
        if(this.wrapper.classList.contains('has-error')){
            this.wrapper.classList.remove('has-error')
            this.el.classList.remove('has-error')
            this.wrapper.querySelector('.error-msg').classList.add('hide')
        }

    }
}

Me.views['FileInput'] = FileInput;
