import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore';
import {MobileNavigation} from "./mobile-navigation";



export class Inputs extends ViewBasic{
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['changeHandler', 'focusHandler', 'blurHandler', 'clickHandler']);
        this.addEvents();
    }

    addEvents() {
        this.el.addEventListener('change', this.changeHandler);
        this.el.addEventListener('focus', this.focusHandler);
        this.el.addEventListener('blur', this.blurHandler);
        this.el.addEventListener('click', this.clickHandler);
    }

    removeEvents() {
        this.el.removeEventListener('change', this.changeHandler);
        this.el.removeEventListener('focus', this.focusHandler);
        this.el.removeEventListener('blur', this.blurHandler);
        this.el.addEventListener('click', this.clickHandler);
    }

    changeHandler(e) {
        if(this.el.value === ''){
            this.el.classList.remove('has-value');
        }
        else{
            this.el.classList.add('has-value');
            this.el.classList.remove('error');

            if(this.el.classList.contains('has-error')){
                this.el.classList.remove('has-error')
                this.el.parentElement.querySelector('.error-msg').classList.add('hide')
            }
        }
    }

    clickHandler(e){
        if(this.el.getAttribute('type') === 'date') {
            try {
                this.el.showPicker();
            } catch (error) {
                this.el.blur();
            }
        }
    }

    focusHandler(e){
        this.el.classList.add('has-value');
        if(e.currentTarget.getAttribute('type') === 'date'){
            // if input is type date and has minimum date set for today
            if(this.el.getAttribute('min') === 'today' && this.el.getAttribute('type') === 'date'){
                const today = new Date().toISOString().split('T')[0];
                this.el.setAttribute('min', today);
            }

            try {
                this.el.showPicker();
            } catch (error) {
                this.el.blur();
            }
        }
    }

    blurHandler(e){
        if(this.el.value === ''){
            this.el.classList.remove('has-value');
        }
    }
}

Me.views['Inputs'] = Inputs;
