if (!window.SETTINGS) {
    window.SETTINGS = {}
}

Object.assign(window.SETTINGS, {
    BASE_URL: window.location.protocol + "//" + window.location.host + "/",
    CURRENT_URL: window.location.protocol + "//" + window.location.host + window.location.pathname,
    VERSIONNING: new Date().getTime(),
    BREAKPOINT: null,
    BREAKPOINTS: {
        LG_MIN: 1380,
        MD_MAX: 1380 - 1,
        MD_MIN: 1024,
        SM_MAX: 1024 - 1,
        SM_MIN: 768,
        XS_MAX: 768 - 1
    }
})

if (process.env.RECAPTCHA_SITE_KEY) {
    window.SETTINGS.RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY
}

if (process.env.SQUARE_APPLICATION_ID) {
    window.SETTINGS.SQUARE_APPLICATION_ID = process.env.SQUARE_APPLICATION_ID
}

if (process.env.SQUARE_LOCATION_ID) {
    window.SETTINGS.SQUARE_LOCATION_ID = process.env.SQUARE_LOCATION_ID
}
