import {Button} from "../button";
import { Overlay } from './overlay';
import {bindAll} from "underscore";

export class BookOverlayBtn extends Button {
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['openBookingOverlay']);
        super.initialize();
    }

    addEvents() {
        super.addEvents();
        this.el.addEventListener('click', this.openBookingOverlay);
    }

    removeEvents() {
        super.removeEvents();
        this.el.removeEventListener('click', this.openBookingOverlay);
    }

    openBookingOverlay(e) {
        window.dispatchEvent(new CustomEvent(Overlay.OVERLAY_OPEN,
            {
                detail: {
                    id: 'overlay-book',
                    activity: this.params.activity,
                    park: this.params.park,
                }
            }
        ));

        e.stopPropagation();
        e.preventDefault();
    }
}

Me.views['BookOverlayBtn'] = BookOverlayBtn;
