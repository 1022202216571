import {ViewBasic} from '@quatrecentquatre/manage-me';
import Swiper, { Navigation } from 'swiper'


export class SwiperTiles extends ViewBasic {
    constructor(options) {
        super(options);
        //set default variables
    }

    initialize() {
        Swiper.use([Navigation]);
        this.newSwiper = this.el.querySelector('.swiper');

        this.addEvents();
    }

    addEvents() {
        this.swiperControl();
    }

    removeEvents() {
        this.swiperControl();
    }

    swiperControl() {
        const swiper = new Swiper(this.newSwiper, {
            slidesPerView: 1,
            spaceBetween: 20,
            navigation: {
                nextEl: '.swiper-next',
                prevEl: '.swiper-prev',
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                },
            },
        });
    }
}

Me.views['SwiperTiles'] = SwiperTiles;
