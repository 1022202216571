import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore';

export class PaymentCart extends ViewBasic {
    constructor(options) {
        super(options);

        this.lang = document.querySelector('html').getAttribute('lang');

        this.cart = {};
        this.promocode = this.params.promocode ?? false;

        this.cartListItemTemplate = '<div class="list-item">\n' +
                                    '    <p class="list-item-infos x-small">\n' +
                                    '        <span data-item-number>%number%</span>\n' +
                                    '        <span> x </span>\n' +
                                    '        <span data-item-name>%name%</span>\n' +
                                    '    </p>\n' +
                                    '    <p class="x-small" data-item-price>%price%</p>\n' +
                                    '</div>';

        this.cartPromoItemTemplate = '<div class="list-item">\n' +
                                    '    <p class="list-item-infos x-small">\n' +
                                    '        <span class="bold" data-item-name>%name%</span>\n' +
                                    '    </p>\n' +
                                    '    <p class="x-small" data-item-price>%price%</p>\n' +
                                    '</div>';
    }

    initialize() {
        bindAll(this, ['updateCartList', 'autofillContent']);
        this.autofillContent();
    }

    formatPrice(price){
        if (typeof price !== 'number') {
            return parseFloat(price.replace(',','.')).toFixed(2);
        } else {
            return parseFloat(price).toFixed(2);
        }
    }

    autofillContent(){
        if(localStorage.getItem("TTG-Cart")){
            this.cart = JSON.parse(localStorage.getItem("TTG-Cart"));
            this.updateCartList();

            // CART TRACKING
            let scope = this;
            let total = 0;
            let items = []
            Object.keys(this.cart).forEach(function(key) {
                let item = {};
                item.price = Number(scope.cart[key].price)/Number(scope.cart[key].value);
                item.currency = 'CAD';
                item.quantity = scope.cart[key].value;
                item.item_id = scope.params.activity_slug;
                item.item_name = scope.params.activity_name;
                item.item_variant = scope.cart[key].name;
                items.push(item);

                total += parseFloat(scope.cart[key].price);
            });
            window.dispatchEvent(new CustomEvent('dataLayer.push', {
                detail: {
                    'event': 'add_to_cart',
                    'ecommerce': {
                        'value': total,
                        'coupon': this.promocode ?? '',
                        'items': items
                    }
                }
            }));
            window.dispatchEvent(new CustomEvent('dataLayer.push', {
                detail: {
                    'event': 'begin_checkout',
                    'ecommerce': {
                        'value': total,
                        'coupon': this.promocode ?? '',
                        'items': items
                    }
                }
            }));

            let taxes = null;
            this.params.taxes.forEach(function (tax){
                taxes += Number(tax.amount.amount);
            });

            let currentReceipt = {};
            currentReceipt.cart = this.cart;
            currentReceipt.promocode = this.promocode;
            currentReceipt.taxes = taxes;
            currentReceipt.park = this.params.park;
            currentReceipt.activityName = this.params.activity_name;
            currentReceipt.activitySlug = this.params.activity_slug;

            localStorage.setItem("TTG-Receipt", JSON.stringify(currentReceipt));
        }
    }

    updateCartList(){
        let scope = this;
        let newListContent = '';
        let newSubtotal = 0;

        Object.keys(this.cart).forEach(function(key) {
            newSubtotal += parseFloat(scope.cart[key].price);
            newListContent += scope.cartListItemTemplate.replace('%number%', scope.cart[key].value)
                                                        .replace('%name%', scope.cart[key].name)
                                                        .replace('%price%', scope.lang === 'fr' ? scope.formatPrice(scope.cart[key].price) + ' $':  '$' + scope.formatPrice(scope.cart[key].price));
        });

        if(this.promocode) {
            let promoTotal = newSubtotal - this.formatPrice(this.params.subtotal);
            newListContent += this.cartPromoItemTemplate.replace('%name%', this.promocode)
                                                       .replace('%price%', scope.lang === 'fr' ? '-' + this.formatPrice(promoTotal) + ' $':  '$' + ' -' + this.formatPrice(promoTotal));
        }

        this.el.querySelector('.cart-list-wrapper .list').innerHTML = newListContent;
    }
}

Me.views['PaymentCart'] = PaymentCart;
