import {string, object} from 'yup';
import {ViewBasic} from '@quatrecentquatre/manage-me';
import {bindAll} from "underscore";

export class InfosForm extends ViewBasic {
    constructor(options) {
        super(options);

        this.phoneRegExp = /^(?:\+?1)?[-. ]?\(?[2-9][0-8][0-9]\)?[-. ]?[2-9][0-9]{2}[-. ]?[0-9]{4}$/i;
        this.postalCodeRegExp = /^((\d{5}-?\d{4})|(\d{5})|([A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d))$/i;
        this.button = this.el.querySelector('[type="submit"]')
        this.form = this.el.querySelector('form')

        this.formFields = [
            {
                name: 'fields[client][firstname]',
                validationSchema: string().required(),
            },
            {
                name: 'fields[client][lastname]',
                validationSchema: string().required(),
            },
            {
                name: 'fields[client][postal_code]',
                validationSchema: string().matches(this.postalCodeRegExp).required(),
            },
            {
                name: 'fields[client][email]',
                validationSchema: string().email().required(),
            },
            {
                name: 'confirm_email',
                validationSchema: string().email().required(),
            },
            {
                name: 'fields[client][phone]',
                validationSchema: string().matches(this.phoneRegExp).required(),
            }
        ];

        let fields = {};
        this.formFields.forEach((value, key) => fields[value.name] = value.validationSchema);
        this.schema = object().shape(fields);
    }

    initialize() {
        bindAll(this, ['submitHandler', 'handleValidationSuccess', 'handleValidationError', 'validateEmails', 'setInfos', 'open']);
        this.addEvents();

        window.dispatchEvent(new Event('resize'));

        let scope = this;
        this.formFields.forEach(function (input, index) {
            if (scope.el.querySelector('[name="'+ input.name +'"]').value) {
                scope.el.querySelector('[name="'+ input.name +'"]').classList.add('has-value');
            }
        })
    }

    addEvents() {
        this.button.addEventListener('click', this.submitHandler);
        window.addEventListener('payment.step-1.open', this.open);
    }

    removeEvents() {
        this.button.removeEventListener('click', this.submitHandler);
        window.removeEventListener('payment.step-1.open', this.open);

    }

    open() {
        this.el.querySelector('.accordion-title').click()
    }

    submitHandler(e) {
        e.preventDefault();

        let formData = new FormData(this.form);
        let formDataObj = {};
        formData.forEach((value, key) => (formDataObj[key] = value));

        let scope = this;
        this.schema
            .validate(formDataObj, {abortEarly: false})
            .then(value => {
                scope.handleValidationSuccess(value);
            })
            .catch(err => {
                scope.handleValidationError(err);
            });
    }

    handleValidationSuccess(value) {
        let scope = this;
        this.formFields.forEach(field => {
            scope.el.querySelector('[name="' + field.name + '"]').classList.remove('has-error');
            let currentErrorFieldMsg = scope.el.querySelector('[name="' + field.name + '"]').parentNode.querySelector('.error-msg');
            currentErrorFieldMsg.classList.add('hide');
            currentErrorFieldMsg.setAttribute("aria-hidden", !0);
        });

        this.validateEmails(value);
    }

    handleValidationError(err) {
        let scope = this;
        JSON.parse(JSON.stringify(err)).inner.forEach(error => {
            scope.el.querySelector('[name="' + error.path + '"]').classList.add('has-error');
            let currentErrorFieldMsg = scope.el.querySelector('[name="' + error.path + '"]').parentNode.querySelector('.error-msg');
            currentErrorFieldMsg.classList.remove('hide');
            currentErrorFieldMsg.setAttribute("aria-hidden", !1);
        });
        window.dispatchEvent(new Event('resize'));
    }

    validateEmails(value) {
        // Validate if both emails are the same
        if (value['fields[client][email]'] === value['confirm_email']) {
            this.setInfos(value);
        } else {
            this.el.querySelector('[name="confirm_email"]').classList.add('has-error');
            let currentErrorFieldMsg = this.el.querySelector('[name="confirm_email"]').parentNode.querySelector('.error-msg');
            currentErrorFieldMsg.classList.remove('hide');
            currentErrorFieldMsg.setAttribute("aria-hidden", !1);
            window.dispatchEvent(new Event('resize'));
        }
    }

    setInfos(clientObject) {
        // Display current infos after form submit
        this.el.querySelector('.display-infos [data-name]').innerHTML = clientObject['fields[client][firstname]'] + ' ' + clientObject['fields[client][lastname]'];
        this.el.querySelector('.display-infos [data-postal-code]').innerHTML = clientObject['fields[client][postal_code]'];
        this.el.querySelector('.display-infos [data-email]').innerHTML = clientObject['fields[client][email]'];
        this.el.querySelector('.display-infos [data-phone]').innerHTML = clientObject['fields[client][phone]'];
        this.el.querySelector('.display-infos').classList.remove('hide');

        // Close current Accordion
        this.el.querySelector('.display-infos').parentElement.click();
        this.el.querySelector('.accordion-trigger').classList.remove('hide');

        // Call to next step to open
        window.dispatchEvent(new CustomEvent('payment.step-2.open'));

        // Call to page JS to save infos
        window.dispatchEvent(new CustomEvent('payment.set-infos', {detail: clientObject}));
    }
}

Me.views['InfosForm'] = InfosForm;
