import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore';

export class Video extends ViewBasic {
    constructor(options) {
        super(options);
        this.video = this.el.querySelector('video');
        this.image = this.el.querySelector('img');
        this.button = this.el.querySelector('button');
    }

    initialize() {
        this.gutterSize = 40;

        bindAll(this, ['playVideo', 'resize', 'scroll']);

        this.addEvents();
        this.resize();
    }

    addEvents() {
        window.addEventListener('scroll', this.scroll);
        window.addEventListener('resize', this.resize);
        if (this.video && this.button) {
            this.button.addEventListener('click', this.playVideo);
        }
    }

    removeEvents() {
        window.removeEventListener('scroll', this.scroll);
        window.removeEventListener('resize', this.resize);
        if (this.video && this.button) {
            this.button.removeEventListener('click', this.playVideo);
        }
    }

    resize() {
        //calculate ratio of container versus page width, apply this value to a css variable so we can use it in a scale on the hero to the same width as the content width;
        document.documentElement.style.setProperty(
            '--container-width-percentage',
            this.el.querySelector('.centered-content').offsetWidth /
                window.innerWidth,
        );
    }

    playVideo(e) {
        this.el.classList.add('playing');
        this.video.play();
    }

    scroll() {
        if (!window.isMobile){
            const currentBlock = this.el;
            const isBlockScrolled =
                document.documentElement.scrollTop >=
                currentBlock.offsetTop -
                (window.innerHeight/2);

            if (isBlockScrolled) {
                currentBlock.classList.add('scrolled');
            } else {
                currentBlock.classList.remove('scrolled');
            }
        }
    }
}

Me.views['Video'] = Video;
