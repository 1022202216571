import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import {bindAll} from "underscore";

export class ContentTransition extends ViewBasic{
    constructor(options) {
        super(options);
        //set default variables
    }

    initialize() {
        bindAll(this, ['createTimeline', 'resetTimeline']);
        // this.createTimeline();
    }

    createTimeline(){
        let scope = this;

        this.ccTimeline = gsap.timeline({
            scrollTrigger: {
                id: 'centered-content',
                trigger: scope.el,
                start: "bottom-=300px",
                end: "bottom",
                scrub: 0.8,
                onUpdate: self => {
                    if (self.progress === 0 || self.progress === 1) {
                        scope.resetTimeline();
                    }
                    //debug
                    // console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());
                }
            }
        });

        this.ccTimeline.fromTo(scope.el, {height: "calc("+ this.el.offsetHeight + "px/100*100)"}, {height: "calc("+ this.el.offsetHeight +"px/100*50)", duration: 4});
    }

    resetTimeline(){
        this.ccTimeline.kill(true);
    }
}

Me.views['ContentTransition'] = ContentTransition;
