import {Filters} from "./tile-filters";
import {bindAll} from "underscore";

export class BookingFilters extends Filters {
    constructor(options) {
        super(options);

        this.currentPark = null;
    }

    initialize() {
        bindAll(this, ['changeHandler', 'addEventCurrentPark', 'removeEventCurrentPark', 'changeCurrentPark', 'openCurrentPark', 'addLoader', 'removeLoader']);
        super.initialize()
    }

    addEvents() {
        super.addEvents()
        window.addEventListener('filters.submit', this.changeHandler);
        window.addEventListener('filters.endChange', this.openCurrentPark);
    }

    removeEvents() {
        super.removeEvents()
        window.removeEventListener('filters.submit', this.changeHandler);
        window.removeEventListener('filters.endChange', this.openCurrentPark);

        this.removeEventCurrentPark();
    }

    addEventCurrentPark() {
        let scope = this;
        let parksTitle = document.getElementById('overlay-book').querySelectorAll('.accordion-title');
        parksTitle.forEach((park) => {
            park.addEventListener('click', scope.changeCurrentPark);
        });
    }

    removeEventCurrentPark() {
        let scope = this;
        let parksTitle = document.getElementById('overlay-book').querySelectorAll('.accordion-title');
        parksTitle.forEach((park) => {
            park.removeEventListener('click', scope.changeCurrentPark);
        });
    }

    changeHandler(e) {
        this.addLoader();
        super.changeHandler(e, false);
    }

    changeCurrentPark(e) {
        this.currentPark = e.currentTarget.getAttribute('data-park');
    }

    openCurrentPark() {
        let scope = this;
        this.removeLoader();
        this.removeEventCurrentPark();
        this.addEventCurrentPark();

        let allParks = document.querySelectorAll('[data-park]');
        if(allParks.length === 1){
            allParks[0].click();
        } else {
            if (this.currentPark && document.querySelector('[data-park="'+ scope.currentPark +'"]')) {
                setTimeout(function (){
                    document.querySelector('[data-park="'+ scope.currentPark +'"]').click();
                }, 100);
            }
        }
    }

    addLoader(){
        const spinnerWrapper = document.createElement('div');
        spinnerWrapper.className = 'spinner-wrapper';
        const loader = document.createElement('span');
        loader.className = 'loader';
        spinnerWrapper.appendChild(loader);

        this.el.appendChild(spinnerWrapper);
    };

    removeLoader() {
        this.el.parentNode.querySelector('.spinner-wrapper')?.remove();
    }
}

Me.views['BookingFilters'] = BookingFilters;
