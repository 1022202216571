import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore';
import gsap from 'gsap';

export class PagebuilderTimeline extends ViewBasic{
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['nextSlide','previousSlide', 'resize']);

        this.index = 1;
        this.slideCount = this.el.querySelectorAll('.dates .date').length;
        this.addEvents();

        this.updateSlideshowStatus();
    }

    addEvents() {
        this.el.querySelector('.next').addEventListener('click', this.nextSlide);
        this.el.querySelector('.previous').addEventListener('click', this.previousSlide);
        window.addEventListener('resize', this.resize);
    }

    removeEvents() {
        this.el.querySelector('.next').removeEventListener('click', this.nextSlide);
        this.el.querySelector('.previous').removeEventListener('click', this.previousSlide);
        window.removeEventListener('resize', this.resize);
    }

    resize(){
        gsap.to(this.el.querySelectorAll('.details'),{height: this.el.querySelectorAll('.detail')[this.index -1].offsetHeight, duration: 0.4, delay: 0.1});
    }

    /**
     * Increase index on next button
     * @param e
     */
    nextSlide(e){
        this.index ++;
        this.updateSlideshowStatus();
    }

    /**
     * Decrease index on previous button
     * @param e
     */
    previousSlide(){
        this.index --;
        this.updateSlideshowStatus();
    }

    /**
     * Update Slideshow status (progress / total slide) and animate the new active slides in.
     * @param e
     */
    updateSlideshowStatus(){
        let scope = this;

        this.el.querySelector('.next').disabled = false;
        this.el.querySelector('.previous').disabled = false;

        if(this.index === this.slideCount){
            this.el.querySelector('.next').disabled = true;
        }else if(this.index === 1){
            this.el.querySelector('.previous').disabled = true;
        }

        //reset active state
        this.el.querySelectorAll('.dates .date').forEach(function(element, index){
            element.classList.remove('like-h1');
            element.classList.remove('hidden');

            if(index < scope.index-1){
                element.classList.add('like-h1');
                element.classList.add('hidden');
            }
        });

        this.el.querySelectorAll('.images img').forEach(function(element, index){
            element.classList.remove('active');
        });

        this.el.querySelectorAll('.detail').forEach(function(element, index){
            element.classList.remove('active');
            gsap.to(element,{opacity:'0', duration: 0.2});
        });


        gsap.to(this.el.querySelector('.dates'),{x:`-${this.el.querySelectorAll('.dates .date')[this.index -1].offsetLeft}px`, duration: 0.6, ease: 'Power1.easeOut'});

        this.el.querySelectorAll('.dates .date')[this.index -1].classList.add('like-h1');
        this.el.querySelectorAll('.images img')[this.index -1].classList.add('active');
        this.el.querySelectorAll('.detail')[this.index -1].classList.add('active');
        gsap.to(this.el.querySelectorAll('.detail')[this.index -1],{opacity:'1', duration: 0.25});
        gsap.to(this.el.querySelectorAll('.details'),{height: this.el.querySelectorAll('.detail')[this.index -1].offsetHeight, duration: 0.4, delay: 0.1});
    }
}

Me.views['PagebuilderTimeline'] = PagebuilderTimeline;
