import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';
import {bindAll} from "underscore";

const OVERLAY_OPEN = 'Overlay.open';
const OVERLAY_CLOSE = 'Overlay.close';

export class Overlay extends ViewBasic {
    static get OVERLAY_OPEN() { return OVERLAY_OPEN; }
    static get OVERLAY_CLOSE() { return OVERLAY_CLOSE; }

    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['open','close','requestClose']);
        this.addEvents();
    }

    addEvents() {
        window.addEventListener(OVERLAY_OPEN, this.open);
        window.addEventListener(OVERLAY_CLOSE, this.close);
        this.el.querySelector('.close').addEventListener('click', this.requestClose);
        this.el.querySelector('.backdrop').addEventListener('click', this.requestClose);
    }

    removeEvents() {
        window.removeEventListener(OVERLAY_OPEN, this.open);
        window.removeEventListener(OVERLAY_CLOSE, this.close);
        this.el.querySelector('.close').removeEventListener('click', this.requestClose);
        this.el.querySelector('.backdrop').removeEventListener('click', this.requestClose);
    }

    open(e){
        if(e.detail.id != this.el.id){
            return;
        }

        //display overlay
        this.el.classList.add('active');
        this.el.setAttribute('aria-hidden', false);

        //remove overflow on body
        document.querySelector('html').classList.add('no-scroll');
        window.lenis.stop();
        this.animateIn();
    }

    animateIn(){
        gsap.fromTo(this.el, {
            y: 30,
            opacity: 0
        },
        {
            y: 0,
            opacity: 1,
            duration: 0.4
        });
    }

    requestClose(e){
        window.dispatchEvent(new CustomEvent(OVERLAY_CLOSE,
            {
                detail: {
                    id: this.el.id
                }
            }
        ));
    }

    close(e){
        if(e.detail){
            if(e.detail.id == this.el.id || e.detail.force && this.el.classList.contains('active')){

                //hide overlay
                this.el.classList.remove('active');
                this.el.setAttribute('aria-hidden', true);

                //add overflow on body
                document.querySelector('html').classList.remove('no-scroll');
                window.lenis.start();
                this.animateOut();
            }
        }
    }

    animateOut(){
        gsap.to(this.el, { opacity:0, duration: 0.3 });
    }
}

Me.views['Overlay'] = Overlay;
