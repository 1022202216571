import {ViewBasic} from '@quatrecentquatre/manage-me';

export class ConfirmationPage extends ViewBasic {
    constructor(options) {
        super(options);

        if (localStorage.getItem("TTG-Receipt")) {
            this.receipt = JSON.parse(localStorage.getItem("TTG-Receipt"));

            // CART TRACKING
            let scope = this;
            let total = 0;
            let items = []
            Object.keys(this.receipt.cart).forEach(function (key) {
                if (key !== 'promocode') {
                    let item = {};
                    item.price = Number(scope.receipt.cart[key].price) / Number(scope.receipt.cart[key].value);
                    item.currency = 'CAD';
                    item.quantity = scope.receipt.cart[key].value;
                    item.item_id = scope.receipt.activitySlug;
                    item.item_name = scope.receipt.activityName;
                    item.item_variant = scope.receipt.cart[key].name;
                    items.push(item);

                    total += parseFloat(scope.receipt.cart[key].price);
                }
            });
            window.dispatchEvent(new CustomEvent('dataLayer.push', {
                detail: {
                    'event': 'purchase',
                    'parc': this.receipt.park,
                    'activite': this.receipt.activity,
                    'ecommerce': {
                        'transaction_id': this.params.bookingNumber,
                        'value': total,
                        'coupon': this.receipt.promocode,
                        'tax': this.receipt.taxes,
                        'items': items
                    }
                }
            }));

            localStorage.removeItem("TTG-Receipt");
        }

        if(localStorage.getItem("TTG-Cart")){
            localStorage.removeItem("TTG-Cart");
        }
    }
}

Me.views['ConfirmationPage'] = ConfirmationPage;
