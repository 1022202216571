import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore';
import { FiltersFormService } from '../../services/filters-form';

export class Filters extends ViewBasic{
    constructor(options) {
        super(options);

        // FiltersFormService for fetch.
        this.service = new FiltersFormService();
        this.selects = this.el.querySelectorAll('select');
        this.inputs = this.el.querySelectorAll('input');
    }

    initialize() {
        bindAll(this, ['changeHandler', 'inputChangeHandler']);

        if(this.inputs.length != 0){
            let url = new URL(window.location.href);
            let params = new URLSearchParams(url.search.slice(1));
            if(params) {
                let currentName = this.inputs[0].name;
                let currentSelectParam = params.get(currentName);
                if (currentSelectParam){
                    this.inputs.forEach((input) => {
                        if(input.value == currentSelectParam){
                            this.el.querySelector('.active').classList.remove('active');
                            input.checked = true;
                            if (this.el.querySelector(`label[for="${input.id}"]`)) {
                                this.el.querySelector(`label[for="${input.id}"]`).classList.add('active');
                            }
                        }
                    })

                }
            }

        }
        this.addEvents();
    }

    addEvents(){
        this.selects.forEach((select) => {
            select.addEventListener('change', this.changeHandler);
        });

        this.inputs.forEach((input) => {
            input.addEventListener('change', this.inputChangeHandler);
        });
    }

    removeEvents(){
        this.selects.forEach((select) => {
            select.removeEventListener('change', this.changeHandler);
        });

        this.inputs.forEach((input) => {
            input.removeEventListener('change', this.inputChangeHandler);
        });
    };

    inputChangeHandler(e){
        //add some code to handle the radio button state change visually
        this.inputs.forEach((input) => {
            if(input != e.currentTarget){
                input.classList.remove('active');
                if (this.el.querySelector('label[for="' + input.id + '"]')){
                    this.el.querySelector('label[for="' + input.id + '"]').classList.remove('active');
                }
            }
        });

        if (this.el.querySelector('label[for="' + e.currentTarget.id + '"]')){
            this.el.querySelector('label[for="' + e.currentTarget.id + '"]').classList.add('active');
        }
        e.currentTarget.classList.add('active');

        //call for the regular on change event bound to the selects
        this.changeHandler(e);
    }

    changeHandler(e, isUpdatingNumberText = true){
        e.preventDefault();

        let formData = new FormData(this.el);
        let url = this.el.getAttribute('action');
        let params = '';
        for (let entry of formData.entries()) {
            if(entry[1]){
                if(params !== ''){
                    if(entry[1] !== "null"){
                        params = params + '&' + entry[0] + '=' + entry[1];
                    }
                } else {
                    if(entry[1] !== "null"){
                        params = entry[0] + '=' + entry[1];
                    }
                }
            }
        }

        let scope = this;
        let updatingSection = document.querySelector(scope.params.selector)
        updatingSection.classList.add('ajax-loading');

        this.service.getFiltersResutls(url + '?' + params)
            .then(response => {

                if(response.errors){
                    // form submit successfully, but errors returned
                    updatingSection.classList.remove('ajax-loading');
                }else{
                    let updatingNumberText = document.querySelector('[data-nb-items]')
                    updatingSection.classList.add('in-transition');

                    //success
                    let html;
                    if (!window.DOMParser) return false;
                    let parser = new DOMParser();
                    try {
                        html = parser.parseFromString(response, 'text/html');
                    } catch(err) {
                        return false;
                    }

                    setTimeout(function (){
                        if(updatingNumberText && isUpdatingNumberText){
                            updatingNumberText.innerHTML = html.querySelector('[data-nb-items]').innerHTML;
                        }

                        updatingSection.innerHTML = html.querySelector(scope.params.selector).innerHTML;
                        updatingSection.classList.remove('in-transition');
                        setTimeout(() => {
                            updatingSection.classList.remove('ajax-loading');
                        }, 200);

                        // Initialize SwitchSiteOverlay after AJAX update
                        Me.manage.initViews();
                        window.dispatchEvent(new CustomEvent("filters.endChange"));

                    },200);
                }
            })
            .catch(error => {
                //PHP error
                scope.el.classList.add('error-php');
                updatingSection.classList.remove('ajax-loading');
            });
    }

    terminate(){
        this.removeEvents();
    };
}

Me.views['Filters'] = Filters;
