import {Overlay} from './overlay';
import Choices from 'choices.js';
import {bindAll} from "underscore";

export class BookOverlay extends Overlay {
    constructor(options) {
        super(options);
        this.form = this.el.querySelector('form');
        this.activityChoice = undefined
        this.parkChoice = undefined

    }

    initialize() {
        super.initialize();
        bindAll(this, ['createActivityChoice', 'createParkChoice']);

        if (window.location.hash) {
            let hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
            if (hash === 'open_booking') {
                // Open Booking Overlay
                window.dispatchEvent(new CustomEvent(Overlay.OVERLAY_OPEN,
                    {
                        detail: {
                            id: 'overlay-book',
                        }
                    }
                ));
                // Remove Booking Hash in URL
                window.history.pushState("", document.title, window.location.pathname + window.location.search);
                setTimeout(function () {
                    // Force filters submit with small timeout for loading purpose
                    window.dispatchEvent(new Event('filters.submit'));
                }, 100);
            }
        }
    }

    createActivityChoice() {
        let activitySelect = document.getElementById('secondaryActivityFilter');
        document.dispatchEvent(new CustomEvent('destroySpecificChoice', {
            detail: {
                element: activitySelect
            }
        }));
        this.noValues = this.el.parentElement.parentElement.parentElement.querySelector('.no-choices');
        this.activityChoice = new Choices(activitySelect, {
            shouldSort: false,
            removeItemButton: true,
            searchEnabled: false,
            searchChoices: false,
            position: 'bottom',
            allowHTML: true,
            noChoicesText: this.noValues.textContent
        });
    }

    createParkChoice() {
        let parkSelect = document.getElementById('secondaryParkFilter');
        document.dispatchEvent(new CustomEvent('destroySpecificChoice', {
            detail: {
                element: parkSelect
            }
        }));
        this.noValues = this.el.parentElement.parentElement.parentElement.querySelector('.no-choices');
        this.parkChoice = new Choices(parkSelect, {
            shouldSort: false,
            removeItemButton: true,
            searchEnabled: false,
            searchChoices: false,
            position: 'bottom',
            allowHTML: true,
            noChoicesText: this.noValues.textContent
        });
    }

    open(e) {
        super.open(e);

        if (this.activityChoice === undefined) this.createActivityChoice();
        if (this.parkChoice === undefined) this.createParkChoice();
        if (e.detail.activity) this.activityChoice.setChoiceByValue(e.detail.activity);
        if (e.detail.park) this.parkChoice.setChoiceByValue(e.detail.park);

        window.dispatchEvent(new Event('filters.submit'));
    }

    close(e) {
        super.close(e);
        if (this.activityChoice !== undefined) {
            this.activityChoice.destroy();
            this.createActivityChoice();
        }
        if (this.parkChoice !== undefined) {
            this.parkChoice.destroy();
            this.createParkChoice();
        }
    }
}

Me.views['BookOverlay'] = BookOverlay;
