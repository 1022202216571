import { string } from 'yup';
import { FormBase } from '@quatrecentquatre/form-me';
import {bindAll} from "underscore";

export class BookingForm extends FormBase {
    constructor(options) {
        super(options);
        //set default variables

        this.buttons = this.$el.querySelectorAll('[type="submit"]')

        this.addFields([
            {
                name: 'fields[event][eventId]',
                validationSchema: string().required(),
            },
            {
                name: 'fields[event][productId]',
                validationSchema: string().required(),
            },
            {
                name: 'fields[event][parkId]',
                validationSchema: string().required(),
            },
            {
                name: 'fields[event][activityId]',
                validationSchema: string().required(),
            },
            {
                name: 'fields[event][eventDate]',
                validationSchema: string().required(),
            },
        ]);

        this.conditionCheckboxes = this.$el.querySelectorAll('.condition-checkbox');
        let scope = this;
        this.conditionCheckboxes.forEach((checkbox) => {
            scope.addField({
                name: checkbox.getAttribute('name'),
                validationSchema: string().required(),
                $error: scope.$el.querySelector('.checkbox-error-msg')
            })
        });

        this.lang = document.querySelector('html').getAttribute('lang');

        this.cart = {};

        this.participantsNumber = {};
        this.totalParticipants = 0;

        this.participants = this.$el.querySelectorAll('.participant-single');
        this.options = this.$el.querySelectorAll('.option-single');

        this.minParticipants = parseInt(this.$el.querySelector('[id="group_min"]').value);
        this.maxParticipants = parseInt(this.$el.querySelector('[id="group_max"]').value);

        this.cartListItemTemplate = '<div class="list-item">\n' +
                                    '    <p class="list-item-infos x-small">\n' +
                                    '        <span data-item-number>%number%</span>\n' +
                                    '        <span> x </span>\n' +
                                    '        <span data-item-name>%name%</span>\n' +
                                    '    </p>\n' +
                                    '    <p class="x-small" data-item-price>%price%</p>\n' +
                                    '</div>';
    }

    initialize() {
        bindAll(this, ['changeHandler', 'setParticipantsObject', 'countParticipants', 'updateCartList', 'addLoader', 'submitHandler', 'autofillContent']);
        super.initialize();

        // check if page as reload, if true and not in error state, clear localStorage cart
        if (window.performance.getEntriesByType('navigation').map((nav) => nav.type).includes('reload') && !this.$el.getAttribute('data-keep-cart')) {
            if(localStorage.getItem("TTG-Cart")){
                localStorage.removeItem("TTG-Cart");
            }
            if(localStorage.getItem("TTG-Receipt")){
                localStorage.removeItem("TTG-Receipt");
            }
        }

        this.enableAdditionBtn();
        this.autofillContent();
    }

    addEvents() {
        this.$el.addEventListener("submit", this.submitHandler);
        this.participants.forEach((parent) => {
            parent.querySelectorAll('input').forEach((i) => {
                i.addEventListener('change', this.setParticipantsObject);
            });
        });
        this.$el.querySelectorAll('input').forEach((input) => {
            input.addEventListener('change', this.changeHandler);
        });
    }

    removeEvents() {
        super.removeEvents();
        this.participants.forEach((parent) => {
            parent.querySelectorAll('input').forEach((i) => {
                i.removeEventListener('change', this.setParticipantsObject);
            });
        });
        this.$el.querySelectorAll('input').forEach((input) => {
            input.removeEventListener('change', this.changeHandler);
        });
    }

    formatPrice(price){
        if (typeof price !== 'number') {
            return parseFloat(price.replace(',','.')).toFixed(2);
        } else {
            return parseFloat(price).toFixed(2);
        }
    }

    autofillContent(){
        if(localStorage.getItem("TTG-Cart")){
            this.cart = JSON.parse(localStorage.getItem("TTG-Cart"));
            this.setParticipantsObject();
            this.updateCartList();
        }
    }

    setParticipantsObject(e){
        if(e) {
            if(e.currentTarget.parentElement.parentElement.classList.contains('has-error')){
                e.currentTarget.parentElement.parentElement.classList.remove('has-error');
                e.currentTarget.parentElement.parentElement.querySelector('.error-msg').classList.add('hide');
            }

            let currentSlug = e.currentTarget.closest('[data-cart-item-selector]').getAttribute('data-slug');
            let currentValue = e.currentTarget.value;

            let currentObject = {
                [currentSlug] : {
                    value: currentValue,
                }
            }

            Object.assign(this.participantsNumber, currentObject);
        } else {
            this.participants.forEach((parent) => {
                parent.querySelectorAll('input').forEach((input) => {
                    let currentSlug = input.closest('[data-cart-item-selector]').getAttribute('data-slug');
                    let currentValue = input.value;

                    let currentObject = {
                        [currentSlug] : {
                            value: currentValue,
                        }
                    }

                    Object.assign(this.participantsNumber, currentObject);
                });
            });
        }

        this.countParticipants();
    }

    countParticipants(){
        let scope = this;

        this.totalParticipants = 0;
        Object.keys(this.participantsNumber).forEach(function(key) {
            scope.totalParticipants += parseInt(scope.participantsNumber[key].value);
        });

        this.$el.querySelector('.participants-error-msg').classList.add('hide');

        if(this.totalParticipants >= this.minParticipants && this.totalParticipants < this.maxParticipants){
            this.enableAdditionBtn();
        } else if(this.totalParticipants === this.maxParticipants) {
            this.disableAdditionBtn();
        }
    }

    enableAdditionBtn(){
        this.participants.forEach((parent) => {
            let currentInput = parent.querySelector('input');
            let currentInputMax = parseInt(currentInput.getAttribute('max'));
            let currentBtnAdd = parent.querySelector('.btn-add');

            if(parseInt(currentInput.value) < currentInputMax){
                currentBtnAdd.classList.remove('disabled');
            }
        });
    }

    disableAdditionBtn(){
        this.participants.forEach((parent) => {
            parent.querySelector('.btn-add').classList.add('disabled');
        });
    }

    changeHandler(e){
        if(!e.currentTarget.closest('[data-cart-item-selector]')){
            return false;
        }

        let currentInputType = e.currentTarget.getAttribute('type') ? e.currentTarget.getAttribute('type') : 'other';
        let currentName = e.currentTarget.closest('[data-cart-item-selector]').getAttribute('data-name');
        let currentSlug = e.currentTarget.closest('[data-cart-item-selector]').getAttribute('data-slug');
        let currentPrice = e.currentTarget.closest('[data-cart-item-selector]').getAttribute('data-price');
        let currentValue = currentInputType === 'number' || currentInputType === 'radio' || currentInputType === 'other' ? e.currentTarget.value : 1;

        if (currentValue == 0 || (currentInputType === 'checkbox' && !e.currentTarget.checked)) {
            let { [currentSlug]: value, ...newCart } = this.cart;
            this.cart = newCart;
        } else {
            let currentObject;
            if (currentInputType === 'radio') {
                currentObject = {
                    [currentSlug] : {
                        type: currentInputType,
                        name: currentValue.split('|')[0],
                        value: 1,
                        price: parseFloat(currentPrice)
                    }
                }
            } else {
                currentObject = {
                    [currentSlug] : {
                        type: currentInputType,
                        name: currentName,
                        value: currentValue,
                        price: parseFloat(currentPrice * currentValue)
                    }
                }
            }
            Object.assign(this.cart, currentObject);
        }

        localStorage.setItem("TTG-Cart", JSON.stringify(this.cart));

        this.updateCartList();
    }

    updateCartList(){
        let scope = this;
        let newListContent = '';
        let newSubtotal = 0;

        Object.keys(this.cart).forEach(function(key) {
            newSubtotal += parseFloat(scope.cart[key].price);
            newListContent += scope.cartListItemTemplate.replace('%number%', scope.cart[key].value)
                                                        .replace('%name%', scope.cart[key].name)
                                                        .replace('%price%', scope.lang === 'fr' ? scope.formatPrice(scope.cart[key].price) + ' $':  '$' + scope.formatPrice(scope.cart[key].price));
        });

        this.$el.querySelector('.cart-list-wrapper .list').innerHTML = newListContent;
        this.$el.querySelector('[data-subtotal-price]').innerHTML =  this.lang === 'fr' ? newSubtotal.toFixed(2) + ' $':  '$' + newSubtotal.toFixed(2);
    }

    async submitHandler(e) {
        let hasError = false;
        this.participants.forEach((parent) => {
            let currentInputValue = parent.querySelector('input').value;
            let currentInputMinimalValue = parseInt(parent.querySelector('input').getAttribute('min'));
            if (currentInputValue < currentInputMinimalValue) {
                parent.classList.add('has-error');
                parent.querySelector('.error-msg').classList.remove('hide');
                hasError = true;
            }
        });

        if(this.totalParticipants < this.minParticipants) {
            this.$el.querySelector('.participants-error-msg').classList.remove('hide');
            if (window.lenis){
                window.lenis.scrollTo(this.$el.querySelector('.participants-error-msg'));
            }
        } else if(hasError){
            if (window.lenis){
                window.lenis.scrollTo(this.$el.querySelector('.participants-wrapper'));
            }
        }
        // else {
        //     // CART TRACKING
        //     let scope = this;
        //     let total = 0;
        //     let items = []
        //     Object.keys(this.cart).forEach(function(key) {
        //         let item = {};
        //         item.price = Number(scope.cart[key].price)/Number(scope.cart[key].value);
        //         item.currency = 'CAD';
        //         item.quantity = scope.cart[key].value;
        //         item.item_id = scope.$el.querySelector('[name="activity_slug"]').value;
        //         item.item_name = scope.$el.querySelector('[name="activity_name"]').value;
        //         item.item_variant = scope.cart[key].name;
        //         items.push(item);
        //
        //         total += parseFloat(scope.cart[key].price);
        //     });
        //     window.dispatchEvent(new CustomEvent('dataLayer.push', {
        //         detail: {
        //             'event': 'add_to_cart',
        //             'ecommerce': {
        //                 'value': total,
        //                 'coupon': this.$el.querySelector('[name="fields[promo_code]"]').value + this.$el.querySelector('[name="fields[season_pass]"]').value,
        //                 'items': items
        //             }
        //         }
        //     }));
        // }

        super.submitHandler(e);
    }

    /**
     * Duplicate to fix JS error in form-me.js
     */
    handleValidationSuccess() {
        this.$el.classList.add(this.classes.valid), this.recaptcha && typeof grecaptcha < "u" ? grecaptcha.execute(SETTINGS.RECAPTCHA_SITE_KEY, { action: this.recaptchaAction }).then((e) => {
            this.$recaptchaInput.value = e, this.handleAjaxSend(this.formatData(new FormData(this.$el)));
        }) : this.handleAjaxSend(this.formatData(new FormData(this.$el)));
    }
    /**
     * Scroll to error position. Query selector finds first error.
     */
    handleValidationError() {
        super.handleValidationError()

        // Scroll to position of error.
        const targetElement = this.$el.querySelector('.has-error');
        const { top } = targetElement.getBoundingClientRect();
        const targetOffset = window.scrollY + top - 200;

        window.scroll({
            top: targetOffset,
            behavior: 'smooth'
        });

    }
    handleAjaxSend(e) {
        // this.button.disabled = true
        this.addLoader();
        // remove mask from phone number
        const t = this.method === "GET" ? `${this.action}?${new URLSearchParams(e)}` : this.action, s = {
            method: this.method,
            cache: "no-cache"
        };
        this.method === "POST" && (s.body = e), fetch(t, s).then((r) => {
            if (!r.ok)
                throw new Error(r.statusText);
            return r.json();
        }).then((r) => {
            r && this.ajaxSuccess(r);
        }).catch((r) => r.response ? r.response.json() : { error: "Unknown error" }).then((r) => {
            r && this.ajaxError(r);
        }).finally(() => {
            this.ajaxComplete();
        });
    }
    ajaxError(error) {
        this.$el.querySelector('.success-message').classList.add('hide');
        this.$el.classList.add(this.classes.serverError);
        this.$el.querySelector('.error-message').innerText = this.$el.querySelector('.error-message').getAttribute('data-default');
        this.$el.querySelector('.error-message').classList?.remove('hide');
    }
    ajaxSuccess(response) {
        if (response.success) {
            // Reset form
            this.$el.reset();

            // Remove has-value classes from all form fields
            let hasValue = this.$el.querySelectorAll('.has-value')
            if(hasValue){
                hasValue.forEach((entry) => {
                    entry.classList.remove('has-value');
                })
            }

            // Dispatch event to the select js
            // Once all fields are reset, we need to destroy the Choices object in Selects.js and re-init
            document.dispatchEvent(new CustomEvent('resetSelect'));

            // Show success message, hide error if visible
            this.$el.querySelector('.success-message').classList?.remove('hide');
            this.$el.querySelector('.error-message').classList?.add('hide');
        } else {
            // Show error message, hide success if visible
            this.$el.querySelector('.success-message').classList?.add('hide');
            this.$el.querySelector('.error-message').innerText = response.errors[0];
            this.$el.querySelector('.error-message').classList?.remove('hide');
        }
    }
    ajaxComplete() {
        super.ajaxComplete();
        this.removeLoader();

        // Enable submit button
        // this.button.disabled = false;

        setTimeout(() =>{
            this.$el.querySelector('.success-message').classList?.add('hide');
        },5000)
    }
    resetFieldState(field){
        // Remove has-error from field
        super.resetFieldState(field);
        if(field.$targetErrorClass){
            field.$targetErrorClass.classList.remove(this.classes.error)
        }
    }
    setFieldState(field, error){
        // Remove has-error to parent field (used for $targetErrorClass at line 22)
        super.setFieldState(field, error);
        if(field.$targetErrorClass){
            field.$targetErrorClass.classList.add(this.classes.error)
        }
    }
    addLoader(){
        const loaderText = this.lang === 'fr' ? 'Chargement...' : 'Loading...';

        const spinnerWrapper = document.createElement('div');
        spinnerWrapper.className = 'spinner-wrapper';
        const loader = document.createElement('span');
        loader.className = 'loader';

        // spinnerBorder.appendChild(loader);
        spinnerWrapper.appendChild(loader);

        this.$el.appendChild(spinnerWrapper);
    };
    removeLoader() {
        this.$el.parentNode.querySelector('.spinner-wrapper')?.remove();
    }
}

Me.forms['BookingForm'] = BookingForm;
