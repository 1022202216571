import lightGallery from 'lightgallery/lightgallery.min';
import lgZoom from 'lightgallery/plugins/zoom/lg-zoom.min';
import "lightgallery/scss/lightgallery-bundle.scss";
import {Hero} from "../sections/commons/hero";

export class GallerySlideshow extends Hero{
    constructor(options) {
        super(options);

        this.cta = this.el.querySelector('.gallery-button');
        this.gallery = this.el.querySelector('.lightgallery');
    }

    initialize() {
        super.initialize();

        this.lightGallery = lightGallery(this.gallery,{
            zoom: true,
            mode: 'lg-slide',
            showZoomInOutIcons: true,
            speed: 350,
            plugins: [lgZoom],
            licenseKey: process.env.LIGHTGALLERY_LICENSE_KEY ?? '',
            mobileSettings: {
                controls: true,
                showCloseIcon: true,
                download: false
            },
            download: false,
            actualSize: false,
            hideScrollbar: true,
            appendSubHtmlTo: '.lg-item',
        });
    }

    addEvents() {
        super.addEvents();

        this.cta.addEventListener('click', () => {
            this.lightGallery.openGallery(0)
        });
    }

    removeEvents() {
        super.removeEvents();

        this.cta.removeEventListener('click', () => {
            this.lightGallery.openGallery(0)
        })
    }

}

Me.views['GallerySlideshow'] = GallerySlideshow;
