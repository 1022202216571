import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore';

export class HelpFindActivities extends ViewBasic {
    constructor(options) {
        super(options);
        // Set default variables
    }

    initialize() {
        bindAll(this, ['updateContent']);
        this.addEvents();

        window.dispatchEvent(new Event('resize'));
    }

    addEvents() {
        this.el.querySelector('#category').addEventListener('change', this.updateContent);
    }

    removeEvents() {
        this.el.querySelector('#category').removeEventListener('change', this.updateContent);
    }

    updateContent(e){
        this.el.querySelectorAll('img').forEach((image) => {
            if(image.getAttribute('data-activity') == e.target.value){
                image.classList.add('active');
            }else{
                image.classList.remove('active');
            }
        });
    }
}

Me.views['HelpFindActivities'] = HelpFindActivities;
