import {ViewBasic} from '@quatrecentquatre/manage-me';

export class ActivityPage extends ViewBasic {
    constructor(options) {
        super(options);
        window.dispatchEvent(new CustomEvent('dataLayer.push', {
            detail: {
                'event': 'view_item',
                'ecommerce': {
                    'items': [{
                        'item_id': this.params.itemId,
                        'item_name': this.params.itemName,
                    }]
                }
            }
        }));
    }
}

Me.views['ActivityPage'] = ActivityPage;
