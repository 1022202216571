import {ViewBasic} from '@quatrecentquatre/manage-me';
import {bindAll} from 'underscore';

export class Accordion extends ViewBasic {
    constructor(options) {
        super(options);

        this.trigger = this.el.querySelector('.accordion-trigger');
        this.title = this.el.querySelector('.accordion-title');
        this.content = this.el.querySelector('.accordion-content');
        this.isTransitioning = false;
    }

    initialize() {
        bindAll(this, ['resize', 'toggle', 'handleEndTransition', 'wrapTags']);
        this.addEvents();
    }

    addEvents() {
        window.addEventListener('resize', this.resize);

        this.trigger.addEventListener("click", this.toggle);
        if (!this.el.getAttribute('data-trigger-only')) {
            this.el.addEventListener("click", this.toggle);
        }

        this.content.addEventListener('transitionend', this.handleEndTransition);
        if (this.title) {
            this.title.addEventListener("click", this.toggle);
        }

        if (this.el.classList.contains('rates-accordion')) {
            window.addEventListener('load', this.wrapTags);
        }
    }

    removeEvents() {
        window.removeEventListener('resize', this.resize);

        this.trigger.removeEventListener("click", this.toggle);
        this.el.removeEventListener("click", this.toggle);

        this.content.removeEventListener('transitionend', this.handleEndTransition);
        if (this.title) {
            this.title.removeEventListener("click", this.toggle);
        }
    }

    toggle(e) {
        // If transitioning, return toggle
        if (this.isTransitioning || e.target.closest('.accordion-content')) {
            return;
        }

        this.isTransitioning = true;

        e.preventDefault();
        e.stopPropagation();

        if (!this.el.classList.contains('active')) {
            this.el.classList.add('is-opening');
            if (this.el.classList.contains('rates-accordion')) {
                setTimeout(() => {
                    this.content.style.overflow = 'visible';
                }, 500)
            }
        } else {
            this.el.classList.add('is-closing');
            this.el.classList.toggle('active');
            if (this.el.classList.contains('rates-accordion')) {
                    this.content.style.overflow = 'hidden';
            }
        }

        if (this.content.style.maxHeight) {
            this.content.style.maxHeight = null;
        } else {
            this.content.style.maxHeight = this.content.scrollHeight + "px";
        }

    }

    handleEndTransition(e) {
        const WATCHED_PROPERTY = ['max-height'];

        if (e.target === this.content && WATCHED_PROPERTY.includes(e.propertyName)) {
            if (this.el.classList.contains('is-opening')) {
                this.el.classList.toggle('active');
                this.isTransitioning = true;
            }
            this.el.classList.remove('is-opening', 'is-closing');

            // Delay transition to avoid user spam toggle
            setTimeout(() =>{
                this.isTransitioning = false;
            }, 150)

        }
    }

    /** Quotes Page *
     *
     *   If the park tags in the Accordion Title wrap on 2 lines or more and the user is in between a tablet and desktop resolution;
     *   Grab every accordion title in the page and force the tags to wrap under the title.
     */

    wrapTags(){

        if (window.innerWidth >= window.SETTINGS.BREAKPOINTS.SM_MAX) {
                this.allTitles = document.querySelectorAll('.accordion-title');
                    // Look if only one title has a higher height than 90px
                    if (this.title.offsetHeight > 90) {
                        // Target all titles and wrap the tags
                        this.allTitles.forEach(entry => {
                            entry.classList.add('tags-wrap');
                        })
                    }
            }

    }


    resize() {
        if (this.el.classList.contains('active')) {
            this.content.style.maxHeight = this.content.scrollHeight + "px";
        }

        if (this.el.classList.contains('rates-accordion')) {
            this.wrapTags();
        }

    }
}

Me.views['Accordion'] = Accordion;
